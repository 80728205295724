import { useEffect, useMemo, useRef, useState } from 'react';
import useSuspenseQuery from 'commons/useSuspenseQuery';
import { useApolloClient } from '@apollo/client';
import { isEqual } from 'lodash';

import removeTypename from 'commons/removeTypename';
import { NUMBER_OF_PRODUCTS_TO_SHOW } from 'commons/constants';
import useIsRefreshCache from 'hooks/useIsRefreshCache';
import addDerivedProductData from './helpers/addDerivedProductData';

// This hook provides a reusable way to load all products for a product listing
// page, such as the category page. It first loads just initial product data
// (including filters), then when the browser is idle it loads all products.

// Has the same hook interface as useSuspenseQuery (query, options) but limit is
// overridden and a default value is provided for fetchPolicy.

// To enable this functionality, it requires that the specified GraphQL query
// supports a limit variable which allows -1 to be passed in to load all.

const useProductListingQuery = (query, options = {}) => {
  const {
    variables,
    limit = NUMBER_OF_PRODUCTS_TO_SHOW,
    skip: skipQuery = false,
    ...otherOptions
  } = options;

  const hasLoadingAllDataStartedRef = useRef(false);
  const prevVariables = useRef({ ...variables });
  const [allData, setAllData] = useState(null);
  const isRefreshCache = useIsRefreshCache();
  const isAllDataInInitialRequest = limit === -1;

  // Get the name of the query (e.g. getCategoryPage) from the query object to
  // support different queries that return the same data structure
  const queryName =
    query?.definitions?.[0]?.selectionSet?.selections?.[0]?.alias?.value ??
    query?.definitions?.[0]?.name?.value;

  // Skip the query if the query name is not available or if skip is set to
  // true in options object
  const skip = skipQuery || !queryName;

  const { data: initialData, loading } = useSuspenseQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...variables,
      limit,
      refreshCache: isRefreshCache,
    },
    skip,
    ...otherOptions,
  });

  const isInitialDataLoading = loading && !initialData;
  const client = useApolloClient();

  useEffect(() => {
    if (isAllDataInInitialRequest || skip) {
      return;
    }

    // If loading of all products has not yet started, load all products when
    // the browser is idle
    if (
      !hasLoadingAllDataStartedRef.current ||
      !isEqual(prevVariables.current, variables)
    ) {
      hasLoadingAllDataStartedRef.current = true;
      prevVariables.current = { ...variables };

      window.requestIdleCallback(async () => {
        const { data: updatedAllData } = await client.query({
          query,
          variables: {
            ...variables,
            limit: -1,
            refreshCache: isRefreshCache,
          },
          skip,
          ...otherOptions,
        });

        setAllData(updatedAllData);
      });
    }
  }, [
    client,
    isAllDataInInitialRequest,
    isRefreshCache,
    otherOptions,
    query,
    skip,
    variables,
  ]);

  const isAllDataLoading = !isAllDataInInitialRequest && !allData;
  const dataResponse = allData ?? initialData;
  const data = dataResponse?.[queryName];

  const pageData = useMemo(() => (data ? removeTypename(data) : {}), [data]);

  // Add any data which can be derived from the response, which is not included
  // in the initial network response for efficiency
  const { products: productsWithoutDerivedData, upholsteryOptions } = pageData;

  const products = useMemo(
    () =>
      productsWithoutDerivedData?.map(product =>
        addDerivedProductData({ product, upholsteryOptions })
      ),
    [productsWithoutDerivedData, upholsteryOptions]
  );

  return {
    ...pageData,
    isAllDataLoading,
    isInitialDataLoading,
    products,
  };
};

export default useProductListingQuery;
