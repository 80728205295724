import { useSetAtom } from 'jotai';

import userAtom from './userAtom';

const useSetUser = () => {
  const setUser = useSetAtom(userAtom);

  return setUser;
};

export default useSetUser;
