import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import ReactTooltip from 'react-tooltip';

import BlockPlaceholder from 'components/consumer/BlockPlaceholder';
import JBImage from 'components/essentials/JBImage';
import HotSpotWrapper from 'components/consumer/hotspot-wrapper';
import browserStorage from 'components/consumer/browser-storage';

const getVerticalScrollPosition = () => window.pageYOffset;

const getPhotoViewportWidth = (isMobile, mobileItemsPerRow) => {
  if (!isMobile) return '25vw';
  if (mobileItemsPerRow === 3) return '33vw';
  if (mobileItemsPerRow === 2) return '50vw';

  return '100vw';
};

class MasonryPhotoCard extends React.Component {
  componentRef = React.createRef();

  state = {
    imageLoaded: false,
    isHovered: false,
  };

  height = `${20 + Math.floor(Math.random() * 20)}vh`;

  componentDidMount() {
    if (this.props.searchPhotoId === this.props.photo.photo_id) {
      this.props.updateSelectedGridItem(this.props.photo, this.props.hits);
    }
  }

  setImageLoaded = () => {
    this.setState({ imageLoaded: true });
  };

  onMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  onMouseLeave = e => {
    this.setState({ isHovered: false });

    if (
      this.componentRef.current &&
      e.relatedTarget.closest &&
      !e.relatedTarget.closest('.hotspot-tooltip-wrapper')
    ) {
      ReactTooltip.hide();
      this.props.setActiveId(null);
    }
  };

  render() {
    const {
      active,
      isMobile,
      match,
      mobileItemsPerRow,
      photo,
      setActiveId,
      showOnTop,
    } = this.props;

    if (!photo) {
      return (
        <div className="w-full min-h-[20vh] text-base text-[#5c5c5c] flex items-center justify-center">
          No product found!
        </div>
      );
    }
    const isSelected =
      (this.props.selectedGridItem &&
        this.props.selectedGridItem.photo_id === photo.photo_id) ||
      this.props.searchPhotoId === photo.photo_id;

    const imgHeight =
      !isMobile && !this.state.imageLoaded ? this.height : undefined;

    const photoElement = (
      <JBImage
        sizes={getPhotoViewportWidth(isMobile, mobileItemsPerRow)}
        onMouseEnter={() => {
          if (!active) {
            ReactTooltip.hide();
            setActiveId(photo.photo_id);
          }
        }}
        alt=""
        src={photo.image_link}
        trim="auto"
        onLoad={this.setImageLoaded}
        className={cx({
          'max-[767px]:h-auto': mobileItemsPerRow === 1,
          'max-[767px]:absolute max-[767px]:inset-0':
            mobileItemsPerRow === 2 || 3,
        })}
      />
    );

    return (
      <button
        type="button"
        className={cx(
          'customer-photo-card__image md:w-[33%] md:lg:w-1/4',
          'relative [transition:transform_.25s_ease-in-out] md:p-4',
          '[&_img]:cursor-pointer [&_img]:align-middle [&_img]:w-full [&_img]:opacity-0 [&_img]:[transition:opacity_.3s_ease-in-out] [&_img]:max-[767px]:object-cover [&_img]:max-[767px]:h-full [&_img]:md:h-auto [&_img]:md:rounded',
          'max-[767px]:before:[content:"_"] max-[767px]:before:block max-[767px]:before:w-full max-[767px]:before:pt-[100%]',
          'max-[767px]:[&_img]:bottom-0 max-[767px]:[&_img]:left-0 max-[767px]:[&_img]:absolute max-[767px]:[&_img]:right-0 max-[767px]:[&_img]:top-0',
          {
            '[&_img]:shadow-[0_0_0_3px_#fff,0_0_0_5px_#262626]': isSelected,
            'hover:md:scale-105 hover:min-[1600px]:transform-[scale(1.03)]': this
              .state.isHovered,
            'md:w-full lg:w-1/2 min-[1520px]:w-[33%]': showOnTop,
            'max-[767px]:relative before:max-[767px]:[content:"_"] before:max-[767px]:block before:max-[767px]:w-full before:max-[767px]:pt-[100%]':
              mobileItemsPerRow === 2 || 3,
          }
        )}
        ref={this.componentRef}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={() => {
          if (this.props.updateSelectedGridItem) {
            if (match.path !== '/free-design-services/design-quiz') {
              this.props.filterActions.onImageSelect(photo.photo_id);
            }
            this.props.updateSelectedGridItem(photo, this.props.hits);
            if (!isMobile && !this.props.noScrollToView) {
              setTimeout(() => {
                if (this.componentRef.current) {
                  this.componentRef.current.scrollIntoView({
                    behavior: 'smooth',
                  });
                }
                browserStorage().setItem(
                  'scrollPosition.customerPhotosPage',
                  getVerticalScrollPosition()
                );
              }, 0);
            }
          }
        }}
        style={{ height: imgHeight }}
      >
        {!this.state.imageLoaded && (
          <BlockPlaceholder className="!w-full !h-full !rounded-[2%]" />
        )}
        {!isMobile ? (
          <HotSpotWrapper
            showTooltip
            showOnActive
            isActive={active}
            src={photo.image_link}
            style={{ width: '100%', height: '100%' }}
          >
            {photoElement}
          </HotSpotWrapper>
        ) : (
          <HotSpotWrapper
            dontFadeTagIcon
            showTooltip
            showOnActive
            fromMobileGrid
            isActive={active}
            src={photo.image_link}
            style={{ width: '100%', height: '100%' }}
          >
            {photoElement}
          </HotSpotWrapper>
        )}

        {isMobile && mobileItemsPerRow === 1 && (
          <div className="p-4 mb-8">
            <div className="text-base font-bold">
              {`${photo.customer_first_name} ${photo.customer_last_name &&
                photo.customer_last_name[0]}`}
            </div>
            <div className="text-sm text-gray">{photo.photo_comment}</div>
          </div>
        )}
      </button>
    );
  }
}

MasonryPhotoCard.propTypes = {
  active: PropTypes.bool,
  filterActions: PropTypes.shape({
    onImageSelect: PropTypes.func,
  }),
  hits: PropTypes.array,
  isMobile: PropTypes.bool,
  match: PropTypes.objectOf({
    path: PropTypes.string,
  }),
  mobileItemsPerRow: PropTypes.number,
  noScrollToView: PropTypes.bool,
  photo: PropTypes.shape({
    customer_first_name: PropTypes.string,
    customer_last_name: PropTypes.string,
    image_link: PropTypes.string,
    photo_comment: PropTypes.string,
    photo_id: PropTypes.number,
  }),
  searchPhotoId: PropTypes.string,
  selectedGridItem: PropTypes.shape({
    photo_id: PropTypes.number,
  }),
  setActiveId: PropTypes.func,
  showOnTop: PropTypes.bool,
  updateSelectedGridItem: PropTypes.func,
};

export default compose(withRouter)(MasonryPhotoCard);
