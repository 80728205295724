import { useCallback, useMemo } from 'react';
import { sortBy } from 'lodash';

import scrollToTopOfProductGrid from 'components/consumer/ProductCardGridUI/helpers/scrollToTopOfProductGrid';
import useProductListingUrlSortByState from './useProductListingUrlSortByState';

const getSortedProducts = (products = [], sortOrder) => {
  if (['featured', 'popularity', 'newest'].includes(sortOrder)) {
    return sortBy(products, product => product.sortOrder[sortOrder]);
  }

  if (sortOrder === 'alphabetical') {
    return sortBy(products, product => product.name.toLowerCase());
  }

  if (sortOrder === 'priceLowToHigh') {
    return sortBy(products, product => product.variants[0].price);
  }

  if (sortOrder === 'priceHighToLow') {
    return sortBy(products, product => product.variants[0].price)
      .slice()
      .reverse();
  }

  return products;
};

const useProductListingSorting = products => {
  const [sortOrder, setSortOrder] = useProductListingUrlSortByState();

  const setSortOrderAndScrollToTop = useCallback(
    updatedSortOrder => {
      scrollToTopOfProductGrid();

      // We use setTimeout here to ensure that the scroll to top occurs as soon
      // as possible and is not blocked by this sorting operation
      setTimeout(() => {
        setSortOrder(updatedSortOrder);
      }, 0);
    },
    [setSortOrder]
  );

  const productsSorted = useMemo(() => getSortedProducts(products, sortOrder), [
    products,
    sortOrder,
  ]);

  const productsSortedWithOutOfStockAtEnd = useMemo(
    () =>
      productsSorted?.sort(
        (productA, productB) =>
          productA.variants[0].isOutOfStock - productB.variants[0].isOutOfStock
      ),
    [productsSorted]
  );

  return {
    productsSorted: productsSortedWithOutOfStockAtEnd,
    sortOrder,
    setSortOrder: setSortOrderAndScrollToTop,
  };
};

export default useProductListingSorting;
