import { useAtomValue } from 'jotai';

import useIsBrowser from 'hooks/useIsBrowser';
import isNewConsumerApp from 'commons/isNewConsumerApp';
import zipCodeFromUserAtom from './zipCodeFromUserAtom';

const DEFAULT_VALUE = '';

// Cookies cannot be read within the server-side render process in the existing
// Consumer app, so we should wait until the app mounts in the browser before
// returning a value. The new Consumer app does not have this limitation, so in
// the new app we always return the value immediately.
const useZipCodeFromUser = () => {
  const isBrowser = useIsBrowser();
  const zipCodeFromUser = useAtomValue(zipCodeFromUserAtom);

  if (!isNewConsumerApp && !isBrowser) {
    return DEFAULT_VALUE;
  }

  return zipCodeFromUser ?? DEFAULT_VALUE;
};

export default useZipCodeFromUser;
