import { useContext } from 'react';

import ProductListingPageContext from 'data-hooks/context/ProductListingPageContext';

const useProductListingPageContext = () => {
  const productListingPageData = useContext(ProductListingPageContext);
  return productListingPageData;
};

export default useProductListingPageContext;
