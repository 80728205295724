import { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import isSearchOpenAtom from './isSearchOpenAtom';

const useToggleIsSearchOpen = () => {
  const setIsSearchOpen = useSetAtom(isSearchOpenAtom);

  const toggleIsSearchOpen = useCallback(() => {
    setIsSearchOpen(isSearchOpen => !isSearchOpen);
  }, [setIsSearchOpen]);

  return toggleIsSearchOpen;
};

export default useToggleIsSearchOpen;
