import { useMemo } from 'react';

import { GET_CLEARANCE_DATA } from 'mocks/queries';
import useZipCode from 'global-state/zipCode/useZipCode';
import useWarehouseIdForUser from 'global-state/warehouseIdForUser/useWarehouseIdForUser';
import useIsBrowser from 'hooks/useIsBrowser';
import useSuspenseQuery from 'commons/useSuspenseQuery';

/* Gives all clearance stock (multiple products) for the user's zipcode
 * skipQuery - Allows skipping clearance stock fetch when used conditionally
 */
const useAllProductClearanceStock = (skipQuery = false) => {
  const isBrowser = useIsBrowser();
  const zipCode = useZipCode();
  const warehouseId = useWarehouseIdForUser();

  const skip = !isBrowser || !zipCode || skipQuery;

  const { loading, data } = useSuspenseQuery(GET_CLEARANCE_DATA, {
    variables: { zipCode, fromCache: true, warehouseId },
    skip,
  });

  const clearanceData = useMemo(() => data?.clearanceData?.data || {}, [
    data?.clearanceData?.data,
  ]);

  return {
    data: clearanceData,
    error: null,
    loading: loading && !data,
  };
};

export default useAllProductClearanceStock;
