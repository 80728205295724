import { useCallback, useMemo } from 'react';

import useQueryParams from 'data-hooks/useQueryParams';
import { DEFAULT_SORT_ORDER } from 'commons/constants';

const SORT_BY_QUERY_KEY = 'sortBy';

const getSortByValueFromQueryParams = queryParams =>
  queryParams[SORT_BY_QUERY_KEY] ?? DEFAULT_SORT_ORDER;

const useProductListingUrlSortByState = () => {
  const [queryParams, setQueryParams] = useQueryParams();

  const sortOrder = useMemo(() => getSortByValueFromQueryParams(queryParams), [
    queryParams,
  ]);

  const setSortOrder = useCallback(
    updatedSortOrder => {
      const updatedQueryParams =
        updatedSortOrder === DEFAULT_SORT_ORDER
          ? {
              [SORT_BY_QUERY_KEY]: null,
            }
          : {
              [SORT_BY_QUERY_KEY]: updatedSortOrder,
            };

      setQueryParams(updatedQueryParams);
    },
    [setQueryParams]
  );

  // Note: the return value of this hook allows us to use this in a very similar
  // way to useState, with one caveat: the set function only supports updates by
  // providing a passing a value rather than passing a callback function.
  return [sortOrder, setSortOrder];
};

export default useProductListingUrlSortByState;
