import useUserRawValue from 'global-state/user/useUserRawValue';

const useUserData = () => {
  const user = useUserRawValue();

  return {
    email: user?.email,
    firstName: user?.first_name,
    lastName: user?.last_name,
    isImpersonatingUser: !!user?.is_impersonate,
    isLoggedIn: !!user,
    isTradeUser: !!user?.is_designer,
    userDiscount: user?.user_discount,
    user,
    userGroupId: user?.group_id,
    userId: user?.id,
  };
};

export default useUserData;
