import isServer from './isServer';

const scrollTo = (...args) => {
  if (isServer()) {
    return;
  }
  try {
    window.scrollTo(...args);
  } catch (err) {
    const { left, top, behavior = 'auto' } = args[0] || {};
    window.scrollTo({ left, top, behavior });
  }
};

export default scrollTo;
