// Filter out any invalid variants from the product, e.g. if color is set to
// blue then only show blue variants

const getIsVariantShownForColorFilter = ({ activeFilters, variant }) => {
  const colorFilterActiveOptions = activeFilters?.color;

  if (!colorFilterActiveOptions?.length) {
    return true;
  }

  const isVariantShownForColorFilter = colorFilterActiveOptions.some(color => {
    const isProductShownForColor =
      // By using color.includes(variant?.color) we can match a color value such
      // as "beige/white" with a color filter value such as "white".
      !!variant?.color && color.includes(variant?.color);
    return isProductShownForColor;
  });

  return isVariantShownForColorFilter;
};

export default getIsVariantShownForColorFilter;
