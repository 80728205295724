import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import SidebarModal from 'components/consumer/SidebarModal';
import withBrowserOnly from 'components/hoc/withBrowserOnly';
import UpdateZipcodeForm from './UpdateZipcodeForm';

const UpdateZipcodeSidebar = ({ onClose, isZipcodeFormOpen }) => {
  const onUpdateSuccess = useCallback(
    (shouldClose = true) => {
      if (shouldClose) {
        onClose?.();
      }
    },
    [onClose]
  );

  return (
    <SidebarModal
      title="Update your location"
      isOpen={isZipcodeFormOpen}
      onClose={onClose}
    >
      <div className="text-sm mb-[5px]">
        Enter your zip code so we can accurately estimate shipping to your
        location
      </div>

      <UpdateZipcodeForm onUpdateSuccess={onUpdateSuccess} />
    </SidebarModal>
  );
};

UpdateZipcodeSidebar.propTypes = {
  isZipcodeFormOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withBrowserOnly(UpdateZipcodeSidebar);
