import React from 'react';
import PropTypes from 'prop-types';
import useRandomId from 'hooks/useRandomId';

import { colorGray } from 'styles/layout/colors';

const IconMapMarker = ({
  color = colorGray,
  height = 22,
  title = 'location',
  width = 18,
}) => {
  const titleId = useRandomId('IconMapMarker__title');

  return (
    <svg
      aria-labelledby={titleId}
      aria-hidden="true"
      fill="none"
      height={height}
      title={title}
      viewBox="0 0 18 22"
      width={width}
    >
      <g>
        <title>{title}</title>
        <path
          strokeWidth="0.1"
          stroke={color}
          fill={color}
          d="m8.96443,0.60528l-0.00042,0.00001c-2.0703,0.01792 -4.05866,0.8102 -5.57333,2.22215c-1.51476,1.41104 -2.44573,3.33792 -2.60992,5.40166l0.04984,0.00397l-0.04984,-0.00396c-0.16412,2.06369 0.45085,4.11329 1.72416,5.74599l-0.00006,0l0.00172,0.002l6.42,7.3997l0.03753,0.0433l0.0378,-0.0431l6.47999,-7.3771l0.0001,0.0001l0.0017,-0.0023l0.0169,-0.0215l0.0106,-0.0136l0,-0.001c1.2652,-1.6305 1.8763,-3.6763 1.7127,-5.73435l0,-0.00003c-0.1651,-2.06371 -1.097,-3.99152 -2.6126,-5.40261c-1.5148,-1.41104 -3.5041,-2.20236 -5.57526,-2.21934l0,0l-0.00042,0l-0.07119,0.00001zm5.70567,12.70412l-5.70352,6.4941l-5.63545,-6.491c-1.11112,-1.4234 -1.6471,-3.2122 -1.50097,-5.0125c0.14711,-1.80062 0.96642,-3.48029 2.29499,-4.70442l0.00001,-0.00002c1.32763,-1.22428 3.06866,-1.90395 4.87483,-1.90395c1.80621,0 3.54731,0.67967 4.87481,1.90395l0,0.00001c1.3286,1.2243 2.1479,2.90398 2.295,4.70443c0.1456,1.7938 -0.3861,3.5764 -1.4892,4.9974l-0.0105,0.012z"
        />
        <path
          strokeWidth="0.1"
          stroke={color}
          fill={color}
          d="m6.10551,9.02772l0.00241,-0.0024c0.04565,1.13797 0.74849,2.15081 1.80697,2.58877c1.09495,0.454 2.35487,0.20313 3.19319,-0.63523c0.83743,-0.83743 1.08825,-2.09831 0.63523,-3.19319l-0.00001,-0.00003c-0.454,-1.09494 -1.52224,-1.80834 -2.70779,-1.80834c-1.61761,0 -2.93,1.31144 -2.93,2.93l0,0.12042zm2.2151,1.60711l-0.00002,-0.00001c-0.69944,-0.28946 -1.15506,-0.97152 -1.15506,-1.72754c0,-1.03271 0.8373,-1.87 1.87,-1.87l0.00949,0c0.7532,0.00377 1.43079,0.45853 1.71903,1.15411c0.28852,0.69948 0.1287,1.50298 -0.40545,2.03805c-0.53509,0.53506 -1.3395,0.69483 -2.03799,0.40539z"
        />
      </g>
    </svg>
  );
};

IconMapMarker.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default IconMapMarker;
