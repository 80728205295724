import { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import validateFormatZipCode from 'commons/validateFormatZipCode';
import zipCodeFromUserAtom from './zipCodeFromUserAtom';

const useSetZipCodeFromUser = () => {
  const setZipCodeFromUserAtom = useSetAtom(zipCodeFromUserAtom);

  const setZipCodeFromUser = useCallback(
    zipCode => {
      if (!zipCode) {
        // If specified zip code is falsy, reset to default value
        setZipCodeFromUserAtom('');
        return;
      }

      if (validateFormatZipCode(zipCode)) {
        // Only store zip codes that have a valid format
        setZipCodeFromUserAtom(zipCode);
      }
    },
    [setZipCodeFromUserAtom]
  );

  return setZipCodeFromUser;
};

export default useSetZipCodeFromUser;
