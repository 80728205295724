import { PRODUCT_GRID_DATA_XC_ID } from 'commons/constants';

import scrollToTop from 'commons/scrollToTop';
import focusOnFirstElement from 'hooks/helpers/focusOnFirstElement';

const scrollToTopOfProductGrid = (shouldFocus = true) => {
  scrollToTop();

  const productGridElement = document.querySelector(
    `[data-xc="${PRODUCT_GRID_DATA_XC_ID}"]`
  );

  if (shouldFocus && productGridElement) {
    focusOnFirstElement(productGridElement);
  }
};

export default scrollToTopOfProductGrid;
