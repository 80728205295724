import { ZIPCODE_FROM_USER_COOKIE_KEY } from 'commons/constants';
import atomWithCookieStorage from 'global-state/helpers/atomWithCookieStorage';

// Note: zipCodeFromUserAtom is used to store the zipcode specified by the user,
// **not** the final zipcode that could be based on location from their browser
// or IP address.
const zipCodeFromUserAtom = atomWithCookieStorage(
  ZIPCODE_FROM_USER_COOKIE_KEY,
  ''
);

export default zipCodeFromUserAtom;
