import { useAtomValue } from 'jotai';

import mobileColumnCountAtom from './mobileColumnCountAtom';

const useMobileColumnCount = () => {
  const mobileColumnCount = useAtomValue(mobileColumnCountAtom);
  return mobileColumnCount;
};

export default useMobileColumnCount;
