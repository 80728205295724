import usePDPOptions from '../context/usePDPOptions';

const usePdpSelectedOptionsVariantSku = () => {
  const pdpSelectedOptionsVariantSku =
    usePDPOptions()?.data?.selectedOptionsVariantSku || '';

  return pdpSelectedOptionsVariantSku;
};

export default usePdpSelectedOptionsVariantSku;
