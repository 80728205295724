// Handle width range filter separately because this involves a single text value separated with a dash
const getIsProductShownForWidthRangeFilter = ({
  activeFilters,
  rangeAttributes,
}) => {
  const productWidth = rangeAttributes?.find(({ id }) => id === 'width')?.value;

  const widthRangeFilterValue = activeFilters?.widthRange?.[0];

  const arrayValueInPageState = widthRangeFilterValue?.split('-') ?? [];
  const [minValueInPageState, maxValueInPageState] = arrayValueInPageState;

  const minValue = parseInt(minValueInPageState ?? '', 10);
  const maxValue = parseInt(maxValueInPageState ?? '', 10);

  if (productWidth && !Number.isNaN(minValue) && productWidth < minValue) {
    return false;
  }

  if (productWidth && !Number.isNaN(maxValue) && productWidth > maxValue) {
    return false;
  }

  return true;
};

export default getIsProductShownForWidthRangeFilter;
