import { useMemo } from 'react';
import useSuspenseQuery from 'commons/useSuspenseQuery';
import { GET_FAVORITED_PRODUCT_IDS } from 'mocks/queries';

const useProductListingWithFavorites = ({ products }) => {
  const { data } = useSuspenseQuery(GET_FAVORITED_PRODUCT_IDS);

  const productsToShowWithFavorites = useMemo(() => {
    const favoritedProductIds = data?.productIds ?? [];
    return products.map(product => ({
      ...product,
      isFavorited: favoritedProductIds.includes(product.id),
    }));
  }, [products, data]);

  return productsToShowWithFavorites;
};

export default useProductListingWithFavorites;