const getLegacyFilterValueInNewFormat = value =>
  value
    .replace('%20', '-')
    .replace('%26', 'and')
    .replace('&', 'and')
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export default getLegacyFilterValueInNewFormat;
