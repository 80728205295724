import React from 'react';
import useSetAuthTokenAndUser from './useSetAuthTokenAndUser';

const withSetAuthTokenAndUser = ComponentToWrap => {
  const WithSetAuthTokenAndUser = props => {
    const setAuthTokenAndUser = useSetAuthTokenAndUser();

    return (
      <ComponentToWrap {...props} setAuthTokenAndUser={setAuthTokenAndUser} />
    );
  };

  WithSetAuthTokenAndUser.displayName = `withSetAuthTokenAndUser(${ComponentToWrap.displayName ||
    ComponentToWrap.name})`;

  return WithSetAuthTokenAndUser;
};

export default withSetAuthTokenAndUser;
