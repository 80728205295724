import { useCallback, useMemo, useRef } from 'react';
import { debounce, omit } from 'lodash';

import scrollToTopOfProductGrid from 'components/consumer/ProductCardGridUI/helpers/scrollToTopOfProductGrid';
import useProductListingUrlFilterState from '../useProductListingUrlFilterState';
import addIsActiveStateToFilter from './addIsActiveStateToFilter';
import getIsProductShown from './helpers/getIsProductShown';
import getIsVariantShownForColorFilter from './helpers/getIsVariantShownForColorFilter';

const getOrderedFilters = (filters = []) => {
  const quickshipFilter = filters.find(({ id }) => id === 'quickship');
  const widthRangeFilter = filters.find(({ id }) => id === 'widthRange');

  const filtersWithoutQuickshipOrWidthRange = filters.filter(
    filter => !['quickship', 'widthRange'].includes(filter.id)
  );

  const orderedFilters = [
    quickshipFilter,
    widthRangeFilter,
    ...filtersWithoutQuickshipOrWidthRange,
  ].filter(filter => !!filter);

  return orderedFilters;
};

const useProductListingFiltering = ({
  filters: initialFilters,
  onlyShowValidFilters = false,
  products,
  resetNumberOfProductsToShowRef,
}) => {
  const filters = useMemo(
    () => [
      {
        id: 'search',
        label: null,
        options: null,
      },
      ...getOrderedFilters(initialFilters),
    ],
    [initialFilters]
  );

  const [activeFilters, setActiveFilters] = useProductListingUrlFilterState(
    filters
  );

  const setActiveFiltersAndScrollToTop = useCallback(
    (updatedFiltersOrCallback, shouldFocus = true) => {
      resetNumberOfProductsToShowRef?.current?.();
      scrollToTopOfProductGrid(shouldFocus);
      setActiveFilters(updatedFiltersOrCallback);
    },
    [resetNumberOfProductsToShowRef, setActiveFilters]
  );

  const resetFilters = useCallback(() => {
    setActiveFiltersAndScrollToTop({});
  }, [setActiveFiltersAndScrollToTop]);

  const toggleFilterOption = useCallback(
    (filterId, optionId) => {
      const activeOptionsForFilter = activeFilters[filterId] ?? [];

      const updatedFilters = activeOptionsForFilter.includes(optionId)
        ? {
            ...activeFilters,
            // If the option is already active, remove it
            [filterId]: activeOptionsForFilter.filter(
              option => option !== optionId
            ),
          }
        : {
            ...activeFilters,
            // If the option is not active, add it
            [filterId]: [...activeOptionsForFilter, optionId],
          };

      setActiveFiltersAndScrollToTop(updatedFilters);
    },
    [activeFilters, setActiveFiltersAndScrollToTop]
  );

  // For any boolean filters, we only want to toggle them on and off, but for
  // compatibility with the activeFilters format we store this as a filter that
  // has a single option with a value of true when active, e.g. { quickship:
  // [true] }
  const toggleBooleanFilter = useCallback(
    filterId => {
      const activeOptionsForFilter = activeFilters[filterId] ?? [];
      const isActive = !!activeOptionsForFilter?.[0];
      const updatedOptionsForFilter = isActive ? [] : [true];

      setActiveFiltersAndScrollToTop({
        ...activeFilters,
        [filterId]: updatedOptionsForFilter,
      });
    },
    [activeFilters, setActiveFiltersAndScrollToTop]
  );

  // Use a ref to access the most recent activeFilters value without causing a
  // new instance of setCustomFilter to be created when the value changes
  const activeFiltersRef = useRef(activeFilters);
  activeFiltersRef.current = activeFilters;

  // For any custom filters, we allow a custom "value" property to be set. For
  // compatibility with the activeFilters format we store this as a filter that
  // has a single option, with the value set to the filtered value, e.g.
  // { search: ['Bryant'] }
  const setCustomFilter = useCallback(
    (filterId, value) =>
      debounce(() => {
        const activeOptionsForFilter = activeFiltersRef.current[filterId] ?? [];

        // Only update if this is a different value
        if (activeOptionsForFilter?.[0] !== value) {
          const updatedFilters = {
            ...activeFiltersRef.current,
            [filterId]: value ? [value] : [],
          };

          // Note: we pass false here so that the focus is not moved to the
          // start of the product grid every time that the user changes the
          // text in the search input
          setActiveFiltersAndScrollToTop(updatedFilters, false);
        }
      }, 300)(),
    [setActiveFiltersAndScrollToTop]
  );

  const attributesForAllProducts = useMemo(() => {
    // Only calculate this if we need to show valid filters
    if (!onlyShowValidFilters || !products?.length) {
      return undefined;
    }

    // Use Map and Set to get all unique values for each attribute, more
    // efficiently than using object and array
    const allAttributesMapOfSets = products.reduce(
      (attributesMapAcc, product) => {
        const attributesForCurrentProduct = product?.attributes ?? [];

        attributesForCurrentProduct.forEach(attribute => {
          const { id: attributeId, values: attributeValues } = attribute;

          const existingValues = attributesMapAcc.get(attributeId) ?? new Set();

          attributeValues.forEach(value => {
            existingValues.add(value);
          });

          attributesMapAcc.set(attributeId, existingValues);
        });

        return attributesMapAcc;
      },
      new Map()
    );

    // Convert the Map of Sets to an object of arrays
    const allAttributesObjectOfArrays = Array.from(
      allAttributesMapOfSets
    ).reduce(
      (acc, [attributeId, valuesSet]) => ({
        ...acc,
        [attributeId]: Array.from(valuesSet),
      }),
      {}
    );

    return allAttributesObjectOfArrays;
  }, [onlyShowValidFilters, products]);

  const productsStateAndActions = useMemo(() => {
    // If leather is selected, remove color filter from activeFilters & filters
    const isLeatherOnlyActiveMaterial =
      activeFilters.material?.length === 1 &&
      activeFilters.material?.includes('leather');
    const activeFiltersFinal = isLeatherOnlyActiveMaterial
      ? omit(activeFilters, 'color')
      : activeFilters;
    const filtersFinal = isLeatherOnlyActiveMaterial
      ? filters.filter(filter => filter.id !== 'color')
      : filters;

    const activeFilterCount = Object.values(activeFiltersFinal).reduce(
      (activeFilterCountAcc, filterOptions) =>
        activeFilterCountAcc + (filterOptions?.length ?? 0),
      0
    );

    const filteredProducts = products?.reduce(
      (filteredProductsAcc, product) => {
        const { attributes = [], rangeAttributes = [], variants } = product;

        const isProductShown = getIsProductShown({
          activeFilterCount,
          activeFilters: activeFiltersFinal,
          attributes,
          productName: product.name,
          rangeAttributes,
          variants,
        });

        // Filter out any invalid variants from the product, e.g. if color is
        // set to blue then only show blue variants
        const productWithValidVariants = {
          ...product,
          variants: variants.filter(variant =>
            getIsVariantShownForColorFilter(variant)
          ),
        };

        return isProductShown
          ? [...filteredProductsAcc, productWithValidVariants]
          : filteredProductsAcc;
      },
      []
    );

    const filtersWithActiveState = filtersFinal?.map(filter =>
      addIsActiveStateToFilter({ filter, activeFilters: activeFiltersFinal })
    );

    const hasWidthRangeFilter = filtersWithActiveState.some(
      filter => filter.id === 'widthRange'
    );

    const widthRangeValues = hasWidthRangeFilter
      ? products
          .map(
            product =>
              product?.rangeAttributes?.find(
                attribute => attribute.id === 'width'
              )?.value
          )
          .filter(Boolean)
      : null;

    const minWidth = widthRangeValues?.length
      ? Math.min(...widthRangeValues)
      : null;
    const maxWidth = widthRangeValues?.length
      ? Math.max(...widthRangeValues)
      : null;

    const filtersWithWidthRangeMinMax = !hasWidthRangeFilter
      ? filtersWithActiveState
      : filtersWithActiveState.map(filter =>
          filter.id === 'widthRange'
            ? { ...filter, minAllowed: minWidth, maxAllowed: maxWidth }
            : filter
        );

    const validFiltersForCurrentProducts = !onlyShowValidFilters
      ? filtersWithWidthRangeMinMax
      : filtersWithWidthRangeMinMax.reduce((acc, currentFilter) => {
          // If the filter has no options, we don't need to do anything
          if (!currentFilter.options) {
            return [...acc, currentFilter];
          }

          const attributesForFilter =
            attributesForAllProducts?.[currentFilter?.id] ?? [];

          const validOptions = currentFilter.options.filter(
            ({ id: optionId }) => attributesForFilter?.includes(optionId)
          );

          if (!validOptions.length) {
            return acc;
          }

          return [
            ...acc,
            {
              ...currentFilter,
              options: validOptions,
            },
          ];
        }, []);

    return {
      activeFilterCount,
      activeFilters: activeFiltersFinal,
      filters: validFiltersForCurrentProducts,
      products: filteredProducts,
      productCount: filteredProducts?.length ?? 0,
      resetFilters,
      setCustomFilter,
      toggleBooleanFilter,
      toggleFilterOption,
    };
  }, [
    activeFilters,
    attributesForAllProducts,
    filters,
    onlyShowValidFilters,
    products,
    resetFilters,
    setCustomFilter,
    toggleBooleanFilter,
    toggleFilterOption,
  ]);

  return productsStateAndActions;
};

export default useProductListingFiltering;
