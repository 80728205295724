import React from 'react';

import BrowserOnly from 'components/consumer/BrowserOnly';

// This higher order component allows us to conditionally render components only
// in the browser, by checking if the component has mounted. This is especially
// useful for preventing components that are not initially visible (e.g. modals)
// from being included in the initial HTML output from a server, which reduces
// initial page load time.

// It should not normally be used for elements that affect initial layout,
// since this will cause a flash of incorrect content in the browser.

// It is suitable for:
// - Conditionally rendering elements that are not displayed initially, e.g.
//   modals, popovers
// - Conditionally rendering elements that do not affect overall layout, e.g.
//   close buttons that are absolutely positioned

const withBrowserOnly = ComponentToWrap => {
  const WithBrowserOnly = props => (
    <BrowserOnly>
      <ComponentToWrap {...props} />
    </BrowserOnly>
  );

  WithBrowserOnly.displayName = `withBrowserOnly(${ComponentToWrap.displayName ||
    ComponentToWrap.name})`;

  return WithBrowserOnly;
};

export default withBrowserOnly;
