import React from 'react';
import PropTypes from 'prop-types';

const IconHeart = ({
  color = '#585858',
  stroke = color,
  fill = 'none',
  ...other
}) => (
  <svg width={20} height={18} viewBox="0 0 20 18" {...other}>
    <path
      d="M17.491 2.794a4.77 4.77 0 0 0-7.347.737A4.77 4.77 0 1 0 2.796 9.54l7.347 7.349L17.49 9.54a4.77 4.77 0 0 0 0-6.746z"
      stroke={stroke}
      fill={fill}
    />
  </svg>
);

IconHeart.propTypes = {
  color: PropTypes.string,
  stroke: PropTypes.string,
  fill: PropTypes.string,
};

export default IconHeart;
