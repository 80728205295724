import isClient from 'commons/isClient';

const refreshFinancingContent = () => {
  if (isClient() && window?.affirm?.ui?.ready) {
    window.affirm.ui.ready(() => {
      window.affirm.ui.refresh();
    });
  }
};

export default refreshFinancingContent;
