const removeNull = obj => {
  if (!obj) {
    return {};
  }
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'undefined' || value === null) {
      delete obj[key];
    }
  });
  return obj;
};

export default removeNull;
