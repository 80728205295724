import { ZIPCODE_ERROR_MESSAGES } from 'commons/constants';
import { logError } from 'commons/logger';

const getLocationErrorMessage = ({
  error,
  isLoading,
  responseStatus,
  zipcode,
}) => {
  // If the zipcode is empty or it is loading, there is no error
  if (!zipcode || isLoading) {
    return undefined;
  }

  // If responseStatus is OK, the zip code is valid and there is no error
  if (responseStatus === 'OK') {
    return undefined;
  }

  // If responseStatus is ZERO_RESULTS, the zip code is not found
  if (responseStatus === 'ZERO_RESULTS') {
    logError(new Error(`Zip code not found: "${zipcode}"`));
    return ZIPCODE_ERROR_MESSAGES.ZIPCODE_NOT_FOUND;
  }

  // All other status codes ("OVER_QUERY_LIMIT", "REQUEST_DENIED",
  // "INVALID_REQUEST", "UNKNOWN_ERROR", "ERROR") should be handled here.
  const errorToThrow =
    error ??
    new Error(
      `Unexpected API error occurred for zip code "${zipcode}", with status: ${responseStatus}`
    );

  logError(errorToThrow);

  return ZIPCODE_ERROR_MESSAGES.UNEXPECTED_ERROR;
};

export default getLocationErrorMessage;
