import { find, get } from 'lodash';
import isClient from 'commons/isClient';
import removeNull from 'commons/removeNull';
import { RECENTLY_VIEWED_KEY, collectionTypesMapping } from 'commons/constants';
import browserStorage from 'components/consumer/browser-storage';
import { logError } from 'commons/logger';

export const EXCLUDED_OPTION_SCREEN_NAMES = [20, 76, 135, 133];

function round(value) {
  if (!value) {
    return value;
  }
  return Math.round(value * 100) / 100;
}
export const getProductURL = ({ productUrlKey, options, pickLater }) => {
  const opts = options
    .map(o =>
      collectionTypesMapping[o.optionTypeId] && o.name
        ? `${
            collectionTypesMapping[o.optionTypeId]
          }=${o.name.toLowerCase().replace(/ /g, '-')}`
        : null
    )
    .filter(Boolean);
  if (pickLater) {
    opts.push('dl=true');
  }
  return `${productUrlKey}?${opts.join('&')}`;
};
export const getAnalyticsDataFromCartItem = item => {
  let fabricOption;
  if (item.pickLater) {
    fabricOption = {
      id: 0,
      name: 'Decide Later',
    };
  } else {
    fabricOption =
      find(item.options, { optionTypeId: 17 }) ||
      find(item.options, { optionTypeId: 18 });
  }
  const woodstainOption =
    find(item.options, { optionTypeId: 19 }) ||
    find(item.options, { optionTypeId: 132 });
  const eventData = {
    // customizations
    discountPrice: round(item.price_post_discount / item.quantity),
    // exposedWood
    fabricId: (fabricOption && fabricOption.id) || '',
    fabricName: (fabricOption && fabricOption.name) || '',
    exposedWood: item.product.exp_wood_product,
    favorited: get(item.product, 'meta.customer.favorited', false),
    // list
    productName: item.name,
    // position
    price: round(item.price_pre_discount / item.quantity),
    productId: item.product_id,
    productFamily: item.product.family && item.product.family.name,
    productType: item.product.type && item.product.type.name,
    // productTypeId
    // productSource
    quickship: get(item.product, 'meta.shipping.quick_ship', false),
    productSource: item.product.efg_flag === 0 ? 'internal' : 'external',
    // rating
    // reviews
    quantity: item.quantity,
    sku: item.product.sku + item.sku,
    woodStain: (woodstainOption && woodstainOption.name) || '',
    woodStainId: (woodstainOption && woodstainOption.id) || '',
    protectionPlan: item.protection_plan_price
      ? round(item.protection_plan_price / item.quantity)
      : null,
  };
  return removeNull(eventData);
};

export const segementDataFromCartItem = (item, eventData) => {
  const segmentData = {
    quantity: eventData.quantity,
    category: eventData.productType,
    label: eventData.productName,
    name: eventData.productName,
    price: eventData.discountPrice,
    product_id: eventData.productId,
    sku: eventData.sku,
    fabric_selected: eventData.fabricName,
    wood_stain: eventData.woodStain,
    variant: item.pickLater ? 'Decide Later' : eventData.fabricName,
  };
  return removeNull(segmentData);
};

export const heapDataFromCartItem = item => {
  let fabricOption;
  if (item.pickLater) {
    fabricOption = {
      id: 0,
      name: 'Decide Later',
    };
  } else {
    fabricOption =
      find(item.options, { optionTypeId: 17 }) ||
      find(item.options, { optionTypeId: 18 });
  }
  const woodstainOption =
    find(item.options, { optionTypeId: 19 }) ||
    find(item.options, { optionTypeId: 132 });
  const heapData = {
    discountPrice: round(item.price_post_discount / item.quantity),
    exposedWood: item.product.exp_wood_product,
    fabricId: fabricOption && fabricOption.id,
    fabricName: fabricOption && fabricOption.name,
    favorited: get(item.product, 'meta.customer.favorited', false),
    imageUrl: item.imageURL,
    quickship: get(item.product, 'meta.shipping.quick_ship', false),
    productName: item.name,
    price: round(item.price_pre_discount / item.quantity),
    productId: item.product_id,
    productFamily: item.product.family && item.product.family.name,
    productType: item.product.type && item.product.type.name,
    // productTypeId
    productSource: item.product.efg_flag === 0 ? 'internal' : 'external',
    // productUrl
    // rating
    // reviews
    quantity: item.quantity,
    sku: item.product.sku + item.sku,
    woodStain: woodstainOption && woodstainOption.name,
    woodStainId: woodstainOption && woodstainOption.id,
    protectionPlan: item.protection_plan_price
      ? round(item.protection_plan_price / item.quantity)
      : null,
  };
  return removeNull(heapData);
};

export const getRecentlyViewed = () => {
  if (isClient()) {
    try {
      return JSON.parse(browserStorage().getItem(RECENTLY_VIEWED_KEY)) || [];
    } catch (err) {
      logError(err, 'Unable to get recently viewed data from browser storage');
      return [];
    }
  }
  return [];
};

export const addToRecentlyViewed = (productIds = []) => {
  const recentlyViewedIds = getRecentlyViewed();
  const updatedRecentlyViewedIds = recentlyViewedIds
    .filter(recentId => recentId && !productIds.includes(recentId))
    .map(productId => productId.toString());

  if (productIds.length && isClient()) {
    updatedRecentlyViewedIds.unshift(...productIds);
    updatedRecentlyViewedIds.splice(10);
    try {
      browserStorage().setItem(
        RECENTLY_VIEWED_KEY,
        JSON.stringify(updatedRecentlyViewedIds)
      );
    } catch (err) {
      logError(err, 'Unable to set recently viewed');
    }
  }
};
