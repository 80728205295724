import { useCallback } from 'react';

import useThrottledWindowScrollPosition from 'hooks/useThrottledWindowScrollPosition';

const useProductListingTrackScrollPosition = ({
  hasScrollPositionBeenRestored,
  lastProductPath,
  loadMoreClickCount,
  previousScrollPosition,
  setScrollDataInSessionStorage,
}) => {
  const onWindowScrollCallback = useCallback(
    updatedScrollPosition => {
      // Wait to start updating scroll position in session storage until
      // previous scroll position has been restored
      if (hasScrollPositionBeenRestored) {
        setScrollDataInSessionStorage(prevData => ({
          ...prevData,
          lastProductPath,
          loadMoreClickCount,
          scrollPosition: updatedScrollPosition,
        }));
      }
    },
    [
      hasScrollPositionBeenRestored,
      lastProductPath,
      loadMoreClickCount,
      setScrollDataInSessionStorage,
    ]
  );

  useThrottledWindowScrollPosition({
    callback: ({ y: updatedScrollPosition }) => {
      if (previousScrollPosition !== updatedScrollPosition) {
        onWindowScrollCallback(updatedScrollPosition);
      }
    },
  });
};

export default useProductListingTrackScrollPosition;
