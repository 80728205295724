import { useContext } from 'react';

import PDPContext from './PDPContext';

const usePDPContext = () => {
  const pdpData = useContext(PDPContext);
  return pdpData;
};

export default usePDPContext;
