import {
  skipToken,
  useSuspenseQuery as useSuspenseQueryOriginal,
  useQuery,
} from '@apollo/client';

import isNewConsumerApp from './isNewConsumerApp';

const useSuspenseQueryNew = (query, originalOptions) => {
  // If skip is truthy, pass skipToken instead of options in the new app to skip
  // the query when using useSuspenseQuery
  const options = originalOptions?.skip ? skipToken : originalOptions;

  return useSuspenseQueryOriginal(query, options);
};

// The old consumer app is unable to use React 18 mode which is required for
// server-side suspense, so useSuspenseQuery from Apollo is only used in the
// new app.

// This hook is used wherever we need to load API data from Apollo Client
const useSuspenseQuery = isNewConsumerApp ? useSuspenseQueryNew : useQuery;

export default useSuspenseQuery;
