import { useAtomValue } from 'jotai';

import userLocationLoadingAtom from './userLocationLoadingAtom';

const useUserLocationLoading = () => {
  const userLocationLoading = useAtomValue(userLocationLoadingAtom);
  return userLocationLoading;
};

export default useUserLocationLoading;
