import { useAtomValue } from 'jotai';

import userAtom from './userAtom';

// This hook returns the raw value of the object stored in the user atom. This
// includes properties that follow legacy naming conventions such as first_name.
// Where possible, we should use the useUser hook instead, which provides
// properties that match the camelCase naming convention, such as firstName.
const useUserRawValue = () => {
  const user = useAtomValue(userAtom);

  return user;
};

export default useUserRawValue;
