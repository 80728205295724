import { useCallback, useEffect } from 'react';

import scrollTo from 'commons/scrollTo';
import poll from 'commons/poll';
import isClient from 'commons/isClient';
import { times } from 'lodash';

const useProductListingRestoreScrollPosition = ({
  isLoadingComplete,
  loadAllProducts,
  loadMoreProducts,
  previousLastProductPath,
  previousLoadMoreClickCount,
  previousScrollPosition,
  setHasScrollPositionBeenRestored,
  skip = false,
}) => {
  const restoreScrollPosition = useCallback(() => {
    const shouldShowAllProducts = previousLoadMoreClickCount === -1;

    if (shouldShowAllProducts) {
      // If the user clicked "Show All" before, then show all products
      loadAllProducts();
    } else if (previousLoadMoreClickCount > 0) {
      // If the user clicked "Load More" previously, then call loadMoreProducts
      // the same number of times, to show the correct number of items
      // Load More button the same number of times
      times(previousLoadMoreClickCount, loadMoreProducts);
    }

    if (previousScrollPosition > 0) {
      // Wait for the last product to render before scrolling to the previous
      // position. We use a fallback timeout of 2.5 seconds here, or 10 seconds
      // if the user previously clicked "Show All" to load all products.
      poll({
        condition: () =>
          isClient() &&
          previousLastProductPath &&
          document.querySelector(
            `[data-xc="product-grid"] [href="${previousLastProductPath}"]`
          ),
        interval: 25,
        maxRetries: shouldShowAllProducts ? 400 : 100,
        callback: () => {
          scrollTo({ top: previousScrollPosition, behavior: 'smooth' });
        },
      });
    }
  }, [
    loadAllProducts,
    loadMoreProducts,
    previousLastProductPath,
    previousLoadMoreClickCount,
    previousScrollPosition,
  ]);

  useEffect(() => {
    // If skip is true or scroll position has already been restored, skip this
    // state update
    if (skip) {
      return;
    }

    setHasScrollPositionBeenRestored(prevHasScrollPositionBeenRestored => {
      // If scroll position has already been restored, skip this state update
      if (prevHasScrollPositionBeenRestored) {
        return prevHasScrollPositionBeenRestored;
      }

      // If user did not previously click Load More then wait for loading of
      // initial data to complete here, but if they clicked Load More then we
      // must instead wait for all data to load and call loadMoreProducts() the
      // correct number of times before restoring scroll position.
      if (isLoadingComplete) {
        restoreScrollPosition();
        return true;
      }

      return false;
    });
  }, [
    isLoadingComplete,
    restoreScrollPosition,
    setHasScrollPositionBeenRestored,
    skip,
  ]);
};

export default useProductListingRestoreScrollPosition;
