import { intersection } from 'lodash';

import getSlugFromLabel from 'commons/getSlugFromLabel';

const getIsProductShownForListFilters = ({ activeListFilters, attributes }) =>
  Object.entries(activeListFilters).reduce(
    (isProductShownAcc, [activeFilterId, activeFilterOptions]) => {
      if (!activeFilterOptions?.length) {
        return isProductShownAcc;
      }

      // TODO: Add support for range filters here: CON-4937

      // For all normal filters, check if there are any active filter
      // options which are *not* found in this product's attributes
      const productAttributesForFilter = (
        attributes?.find(({ id }) => id === activeFilterId)?.values ?? []
      )
        // Convert to standard slug format to match API, e.g. `3+ Seater` to
        // `3-seater` and `72"-to-86"` to `72-to-86`
        .map(getSlugFromLabel);

      const matchingFilterOptionsFoundInProduct = intersection(
        productAttributesForFilter,
        activeFilterOptions
      );

      const isProductShownForFilter =
        matchingFilterOptionsFoundInProduct.length > 0;

      if (!isProductShownForFilter) {
        return false;
      }

      return isProductShownAcc;
    },
    true
  );

export default getIsProductShownForListFilters;
