import { useCallback } from 'react';
import { useQuery } from '@apollo/client';

import useZipCode from 'global-state/zipCode/useZipCode';
import {
  GET_CART,
  GET_FAVORITED_PRODUCT_IDS,
  GET_USER_FABRIC_FAVORITES,
} from 'mocks/queries';

// This hook is used to refetch necessary data after a user logs in or logs out
const useRefetchAfterAuthChange = () => {
  const userZipcode = useZipCode();

  const { refetch: refetchCart } = useQuery(GET_CART, {
    variables: { zipCode: userZipcode || null },
  });

  const { refetch: refetchFavoritedProductIds } = useQuery(
    GET_FAVORITED_PRODUCT_IDS
  );

  const { refetch: refetchUserFabricFavorites } = useQuery(
    GET_USER_FABRIC_FAVORITES
  );

  const refetchAfterAuthChange = useCallback(
    async () =>
      Promise.all([
        refetchCart(),
        refetchFavoritedProductIds(),
        refetchUserFabricFavorites(),
      ]),
    [refetchCart, refetchFavoritedProductIds, refetchUserFabricFavorites]
  );

  return refetchAfterAuthChange;
};

export default useRefetchAfterAuthChange;
