// This poll function calls the callback function once the condition function
// returns true.
const poll = ({
  callback = () => {},
  errorCallback = () => {},
  condition = () => true,
  interval = 50,
  maxRetries = 200,
}) => {
  let retries = 0;

  const pollInterval = setInterval(() => {
    if (retries > maxRetries) {
      clearInterval(pollInterval);
      errorCallback();
      return;
    }

    const isComplete = condition();

    if (!isComplete) {
      retries += 1;
      return;
    }

    clearInterval(pollInterval);

    callback();
  }, interval);

  // The function returned will clear the interval, which should be used as the
  // cleanup function when poll() is called within a useEffect hook.
  return () => clearInterval(pollInterval);
};

export default poll;
