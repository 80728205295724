import { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import favoriteModalStateAtom from './favoriteModalStateAtom';
import defaultValue from './defaultValue';

const useSetFavoriteModalState = () => {
  const setFavoriteModalStateAtom = useSetAtom(favoriteModalStateAtom);

  const setFavoriteModalState = useCallback(
    ({ productId}) => {
      if (productId) {
        setFavoriteModalStateAtom({
          isOpen: true,
          productId,
        });
      } else {
        setFavoriteModalStateAtom(defaultValue);
      }
    },
    [setFavoriteModalStateAtom]
  );

  return setFavoriteModalState;
};

export default useSetFavoriteModalState;
