import { get, set } from 'lodash';

export const calculate = (original, crop) => {
  const {
    originalWidth,
    originalHeight,
    hotspotX,
    hotspotY,
    left,
    right,
  } = original;
  const { fX, fY, fZ, newWidth, newHeight } = crop;
  const totalNewWidth = newWidth / fZ;
  const totalNewHeight = newHeight / fZ;
  let minX = fX - totalNewWidth / 2;
  let maxX = fX + totalNewWidth / 2;
  let minY = fY - totalNewHeight / 2;
  let maxY = fY + totalNewHeight / 2;

  if (totalNewWidth > originalWidth || totalNewHeight > originalHeight) {
    return {
      hotspotX,
      hotspotY,
      left,
      right,
    };
  }
  if (minX < 0) {
    maxX += Math.abs(minX);
    minX = 0;
  }
  if (maxX > originalWidth) {
    minX -= (maxX - originalWidth);
    maxX = originalWidth;
  }
  if (minY < 0) {
    maxY += Math.abs(minY);
    minY = 0;
  }
  if (maxY > originalHeight) {
    minY -= (maxY - originalHeight);
    maxY = originalHeight;
  }
  if (
    hotspotX > minX &&
    hotspotX < maxX &&
    hotspotY > minY &&
    hotspotY < maxY
  ) {
    return {
      hotspotX: hotspotX - minX,
      hotspotY: hotspotY - minY,
      left: `${(hotspotX / totalNewWidth) * 100}%`,
      top: `${(hotspotY / totalNewHeight) * 100}%`,
    };
  }
  return null;
};

export const setReactTooltipValues = (key, value) =>
  set(window, `react-tooltip.${key}`, value);
export const getReactTooltipValues = key => get(window, `react-tooltip.${key}`);
