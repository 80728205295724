import { useCallback, useState } from 'react';

import { logInfo } from 'commons/logger';

// just a hook to forceUpdate the component;
export const useForceUpdate = () => {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick(tick => tick + 1);
  }, []);
  return update;
};

const notificationBannerHelper = () => {
  // need a reference inside the hook used to update it whenever needed
  const reactComponent = {
    update: logInfo,
  };
  const setUpdater = update => {
    reactComponent.update = update;
  };

  const defaultWelcomeMsgStateCache = {
    type: null,
    props: {},
  };
  let welcomeMsgStateCache = null;
  let timer;

  const hideMsg = () => {
    welcomeMsgStateCache.type = null;
    reactComponent.update();
  };

  const showMsg = (type, props, timeout = 5000) => {
    welcomeMsgStateCache = {
      type,
      props,
    };
    reactComponent.update();

    timer = window.setTimeout(hideMsg, timeout);
  };

  const removeTimeout = () => {
    clearTimeout(timer);
  };
  const hideMsgWithTimeout = () => {
    window.setTimeout(hideMsg, 5000);
  };

  const getWelcomeMsgState = () =>
    welcomeMsgStateCache || defaultWelcomeMsgStateCache;

  return {
    getWelcomeMsgState,
    showMsg,
    hideMsg,
    setUpdater,
    removeTimeout,
    hideMsgWithTimeout,
  };
};

export default notificationBannerHelper();
