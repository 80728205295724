import { omit, isNil, isObject } from 'lodash';

// Note: this function is based on the omit-deep-lodash package

// Recursively remove all specified properties from the given object
function omitDeep(input, properties) {
  function omitDeepOnOwnProperties(obj) {
    if (typeof input === 'undefined') {
      return input;
    }

    if (!Array.isArray(obj) && !isObject(obj)) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return omitDeep(obj, properties);
    }

    const o = {};

    for (const [key, value] of Object.entries(obj)) {
      o[key] = !isNil(value) ? omitDeep(value, properties) : value;
    }

    return omit(o, properties);
  }

  if (arguments.length > 2) {
    // eslint-disable-next-line no-param-reassign, prefer-rest-params
    properties = Array.prototype.slice.call(arguments).slice(1);
  }

  if (Array.isArray(input)) {
    return input.map(omitDeepOnOwnProperties);
  }

  return omitDeepOnOwnProperties(input);
}

export default omitDeep;
