import { isEqual } from 'lodash';

import getLegacyFilterValueInNewFormat from './getLegacyFilterValueInNewFormat';

const NON_LIST_FILTERS = ['search', 'quickship', 'widthRange'];

const getValidActiveFilters = ({ activeFilters, filters, setQueryParams }) => {
  const activeFiltersArray = Object.entries(activeFilters);

  const activeListFiltersArray = activeFiltersArray.filter(
    ([filterName]) => !NON_LIST_FILTERS.includes(filterName)
  );

  const activeNonListFiltersArray = activeFiltersArray.filter(([filterName]) =>
    NON_LIST_FILTERS.includes(filterName)
  );

  // If there are no list filters, return the activeFilters without changes
  if (activeListFiltersArray.length === 0) {
    return activeFilters;
  }

  const validActiveListFiltersArray = activeListFiltersArray.map(
    ([filterName, filterValues]) => {
      const filter = filters.find(({ id }) => id === filterName);
      const filterOptions = filter?.options ?? [];

      // If filters are passed in as a comma-separated string, split them into
      // an array then flatten back into a single array
      const splitFilterValues = filterValues.flatMap(filterValue =>
        filterValue.split(',')
      );

      const updatedFilterValues = splitFilterValues.reduce(
        (acc, filterValue) => {
          const isFilterValueValid = filterOptions.some(
            ({ id }) => id === filterValue
          );

          if (isFilterValueValid) {
            return [...acc, filterValue];
          }

          // If filter is not valid, try to convert it to a valid filter
          const filterValueInNewFormat = getLegacyFilterValueInNewFormat(
            filterValue
          );

          const isFilterValueValidInNewFormat = filterOptions.some(
            ({ id }) => id === filterValueInNewFormat
          );

          if (isFilterValueValidInNewFormat) {
            return [...acc, filterValueInNewFormat];
          }

          // If filter is not valid after converting to new format, do not add it
          // to array of valid active filters
          return acc;
        },
        []
      );

      return [
        filterName,
        updatedFilterValues?.length ? updatedFilterValues : null,
      ];
    }
  );

  const validActiveFilters = Object.fromEntries([
    ...validActiveListFiltersArray,
    ...activeNonListFiltersArray,
  ]);

  // If any filter values have been changed then update the query params in the
  // URL to replace any invalid filter values (e.g.
  // category=sofas%20sectionals%20%26%20loveseats) with valid filter options
  // (e.g. category=sofas-sectionals-and-loveseats)
  if (!isEqual(activeFilters, validActiveFilters)) {
    setQueryParams(validActiveFilters);
  }

  return validActiveFilters;
};

export default getValidActiveFilters;
