import { useCallback, useMemo, useRef } from 'react';
import { isArray } from 'lodash';

import useQueryParams from 'data-hooks/useQueryParams';
import getValidActiveFilters from './helpers/getValidActiveFilters';

// Remove any non-filter params
const getActiveFiltersFromQueryParams = ({ queryParams, filterIds }) =>
  Object.entries(queryParams).reduce(
    (acc, [currentKey, currentValue]) => ({
      ...acc,
      ...(filterIds.includes(currentKey)
        ? {
            // If value is not already an array, wrap it in an array
            [currentKey]: isArray(currentValue) ? currentValue : [currentValue],
          }
        : null),
    }),
    {}
  );

const useProductListingUrlFilterState = filters => {
  const filterIds = useMemo(() => filters.map(({ id }) => id), [filters]);
  const [queryParams, setQueryParams] = useQueryParams();

  const activeFilters = useMemo(() => {
    const activeFiltersFromUrl = getActiveFiltersFromQueryParams({
      filterIds,
      queryParams,
    });

    const validActiveFilters = getValidActiveFilters({
      activeFilters: activeFiltersFromUrl,
      filters,
      setQueryParams,
    });

    return validActiveFilters;
  }, [filterIds, filters, queryParams, setQueryParams]);

  // Use a ref to access the most recent activeFilters value without causing a
  // new instance of setActiveFilters to be created when the value changes
  const activeFiltersRef = useRef(activeFilters);
  activeFiltersRef.current = activeFilters;

  const setActiveFilters = useCallback(
    updatedActiveFilters => {
      // Create an object containing a null value for each filter, so that any
      // filters not specified in updatedActiveFilters are reset
      const nullFilters = Object.keys(activeFiltersRef.current).reduce(
        (acc, currentKey) => ({
          ...acc,
          [currentKey]: null,
        }),
        {}
      );

      // Remove any non-filter params
      const updatedActiveFiltersWithNulls = Object.entries(
        updatedActiveFilters
      ).reduce(
        (acc, [currentKey, currentValue]) => ({
          ...acc,
          // If value array is empty, set as null to remove from query params
          [currentKey]: currentValue?.length ? currentValue : null,
        }),
        nullFilters
      );

      setQueryParams(updatedActiveFiltersWithNulls);
    },
    [setQueryParams]
  );

  // Note: the return value of this hook allows us to use this in a very similar
  // way to useState, with one caveat: the set function only supports updates by
  // providing a passing a value rather than passing a callback function.
  return [activeFilters, setActiveFilters];
};

export default useProductListingUrlFilterState;
