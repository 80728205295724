import { getVariantsInOrder } from 'commons/constants';

// Find the lowest tier in the variants array and ensure that the first variant
// is the lowest tier. E.g. if the lowest tier is tier 1, then check if the
// first variant is a tier 1 variant - if it is not, then move the first tier 1
// variant found to the start of array.

const getLowestTierVariantFirst = (variants = [], productId) => {
  const lowestTier = variants.reduce((lowestTierSoFar, variant) => {
    const { tier } = variant;

    return tier < lowestTierSoFar || lowestTierSoFar === null
      ? tier
      : lowestTierSoFar;
  }, null);

  if (lowestTier === null) {
    return variants;
  }

  const firstLowestTierVariant = variants.find(
    option => option.tier === lowestTier
  );

  // Refer https://stitchindustries.atlassian.net/browse/CON-6770
  // pseudo-randomize the displayed fabric by matching the last digit of
  // the product id to one of our tier 1 fabrics
  // pseudoRandomVariantSequence is an array of preferred fabrics to show where
  // the index of the array corresponds to the last digit of the product id

  const firstVariantToShowBasedOnLastDigitOfProductId =
    getVariantsInOrder[productId % 10];
  const firstVariant =
    variants.find(
      option => option.id === firstVariantToShowBasedOnLastDigitOfProductId
    ) || firstLowestTierVariant;
  const firstVariantId = firstVariant.id;

  const variantsWithoutFirstVariant = variants.filter(
    variant => variant.id !== firstVariantId
  );

  return [firstVariant, ...variantsWithoutFirstVariant];
};

export default getLowestTierVariantFirst;
