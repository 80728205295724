import { useMemo } from 'react';
import queryString from 'query-string';

import isClient from 'commons/isClient';

const useIsRefreshCache = () => {
  const isRefreshCache = useMemo(() => {
    if (isClient()) {
      const queryParams = queryString.parse(window.location.search);
      const { refreshCache } = queryParams;

      if (refreshCache) {
        return true;
      }
    }

    return false;
  }, []);

  return isRefreshCache;
};

export default useIsRefreshCache;
