import React from 'react';
import PropTypes from 'prop-types';

import getCropFormat from 'commons/getCropFormat';
import useScreen from 'hooks/useScreen';
import isT1Image from 'commons/isT1Image';
import JBImage from './index';

const JBProductImage = props => {
  const { isMobileOrTablet } = useScreen();

  const {
    enableCropIfCropFactorAvailable,
    isFullScreenGallery = false,
    onError,
    type,
    ...jbImageProps
  } = props;

  const isConfiguration = type === 'configuration' || type === 'cylindo';
  const isStudio = type === 'studio';
  const isT1 = isT1Image(props.src);

  const {
    enableCrops = false,
    crops,
    cropFormat: initialCropFormat,
    ...otherProps
  } = jbImageProps;

  const cropFormat =
    enableCropIfCropFactorAvailable ||
    initialCropFormat ||
    getCropFormat({ enableCrops, isMobileOrTablet });

  if (cropFormat && crops?.[cropFormat]) {
    // If manual crop data is defined, then use that to render image
    return (
      <JBImage
        {...jbImageProps}
        enableCrops={!!enableCropIfCropFactorAvailable || enableCrops}
        cropFormat={cropFormat}
        onError={onError}
      />
    );
  }

  if (enableCrops && (isConfiguration || isStudio)) {
    // Base params. Individual parameters can be overriden in the sections below as required.
    let finalProps = {
      ar: isMobileOrTablet ? '1:1' : '3:2',
      crop: 'center',
      trim: 'color',
      trimColor: 'F5F6F8',
      trimTol: 50,
      trimPad: 50,
      fit: 'fill',
      fill: 'solid',
      fillColor: 'F5F6F8',
      ...jbImageProps,
    };

    if (isStudio && isT1) {
      finalProps = {
        ...finalProps,
        // studio t1 overrides below
        trimPad: 180,
      };

      if (isMobileOrTablet) {
        finalProps = {
          ...finalProps,
          // mobile/tablet + studio t1 overrides
        };
      }
    }

    if (isFullScreenGallery && isStudio && isT1) {
      finalProps = {
        ...finalProps,
        // fullscreen gallery + studio t1 overrides below
        fit: 'max',
        fillColor: 'fff',
        pad: 240,
        trimTol: 2,
        bg: 'F5F6F8',
      };

      if (isMobileOrTablet) {
        finalProps = {
          ...finalProps,
          // mobile/tablet + fullscreen gallery + studio t1 overrides below
          pad: 80,
        };
      }
    }

    if (isConfiguration) {
      finalProps = {
        ...finalProps,
        // config overrides below
        fit: 'fill',
        crop: 'entropy',
        fillColor: 'fff',
        trimColor: 'none',
        pad: 40,
      };

      if (isMobileOrTablet) {
        finalProps = {
          ...finalProps,
          // mobile/tablet + config overrides below
          fit: 'max',
          pad: 20,
        };
      }
    }

    if (isFullScreenGallery && isConfiguration) {
      finalProps = {
        ...finalProps,
        //  fullscreen gallery + config overrides below
      };

      if (isMobileOrTablet) {
        finalProps = {
          ...finalProps,
          // mobile/tablet +  fullscreen gallery + config overrides below
        };
      }
    }

    return <JBImage {...finalProps} onError={onError} />;
  }

  const imageProps = {
    // If manual crop data is undefined and is a config photo, trim whitespace
    trim: isConfiguration ? 'color' : undefined,
    trimPad: isConfiguration ? 50 : undefined,
    // If manual crop data is undefined and is a studio photo, fall back to 2:1
    // aspect ratio crop
    ar: isStudio ? '2:1' : undefined,
    crop: isStudio ? 'center' : undefined,
    ...otherProps,
  };

  return <JBImage {...imageProps} {...props} />;
};

JBProductImage.propTypes = {
  crops: PropTypes.shape({
    '1x1': PropTypes.object,
    '3x2': PropTypes.object,
  }),
  enableCropIfCropFactorAvailable: PropTypes.bool,
  enableCrops: PropTypes.bool,
  isFullScreenGallery: PropTypes.bool,
  onError: PropTypes.func,
  src: PropTypes.string,
  type: PropTypes.oneOf(['studio', 'configuration']),
};

export default JBProductImage;
