import { useCallback, useEffect, useState } from 'react';
import isServer from 'commons/isServer';

export const dispatchCustomEvent = (eventType, data, target) => {
  if (isServer()) {
    // eslint-disable-next-line no-console
    console.log('CustomEvent dispatch invoked on server, ignoring.', eventType);
    return;
  }
  (target || window).dispatchEvent(new CustomEvent(eventType, data));
};

const useCustomEvent = ({ initialValue = null, type, target }) => {
  const [event, listener] = useState(initialValue);

  const dispatch = useCallback(
    properties => {
      if (!isServer()) {
        dispatchCustomEvent(type, properties, target);
      }
    },
    [type, target]
  );

  useEffect(() => {
    const eventTarget = target || window;

    if (!isServer()) {
      eventTarget.addEventListener(type, listener);
    }

    return () => {
      if (!isServer()) {
        eventTarget.removeEventListener(type, listener);
      }
    };
  }, [type, target]);

  if (isServer()) {
    return {};
  }

  return {
    dispatch,
    event,
  };
};

export default useCustomEvent;
