import { useRef } from 'react';

import useOnOpen from 'hooks/useOnOpen';
import getFirstFocusableElement from '../helpers/getFirstFocusableElement';

const useFocusOnOpen = isOpen => {
  const elementRef = useRef();

  useOnOpen(isOpen, () => {
    if (elementRef?.current) {
      const firstFocusableElement = getFirstFocusableElement(
        elementRef.current
      );

      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  });

  return elementRef;
};

export default useFocusOnOpen;
