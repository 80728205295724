import { find, cloneDeep, isEmpty, get as lodashGet } from 'lodash';
import queryString from 'query-string';

import addDomainToPath from 'commons/addDomainToPath';
import buildFinalHeroImageURL from 'commons/buildHeroImageURL';
import {
  WOOD_PRODUCT_OPTION_ID,
  FABRIC_IMG_URL,
  FRAME_PRODUCT_OPTION_ID,
  FABRIC_PRODUCT_OPTION_ID,
  SHELF_FINISH_PRODUCT_OPTION_ID,
  LEATHER_PRODUCT_OPTION_ID,
  WOOD_STAIN_PRODUCT_OPTION_ID,
  VENDOR_LEATHER_PRODUCT_OPTION_ID,
  PRODUCT_OPTION_URL_SUFFIX,
  FINISH_PRODUCT_OPTION_ID,
  SIZE_PRODUCT_OPTION_ID,
  GLASS_PRODUCT_OPTION_ID,
  VALID_HERO_DISPLAY_PRODUCT_OPTIONS,
  RUGS_SIZE_PRODUCT_OPTION_ID,
  WALLPAPER_COLOR_PRODUCT_OPTION_ID,
} from './constants';

export const validPrimaryProductIds = [
  FABRIC_PRODUCT_OPTION_ID,
  LEATHER_PRODUCT_OPTION_ID,
  FRAME_PRODUCT_OPTION_ID,
  VENDOR_LEATHER_PRODUCT_OPTION_ID,
];
// Utility methods for use by the new PDPv2 data-hooks

export function checkInAllOptionsV2(options, partSku) {
  if (!options?.length) {
    return [];
  }
  let matchedOptionValue = null;
  let matchedOption = null;
  // eslint-disable-next-line no-unused-vars
  for (const opt of options) {
    const theOpt = opt;
    matchedOptionValue = find(theOpt.values, {
      sku: `-${partSku}`,
    });
    if (matchedOptionValue) {
      matchedOption = theOpt;
      break;
    }
  }

  return [matchedOption, matchedOptionValue];
}

export function findOptionAndTypeFromSkuV2({ options, partSku }) {
  let finalOptionValue = null;
  let upholsteryOption = null;
  let optionTypeId = null;
  let optionName = null;
  let optionValue = null;
  if (partSku.match(/CF[0-9]*/)) {
    upholsteryOption = find(options, { name: 'Fabric' });
  } else if (partSku.match(/CL[0-9]*/i)) {
    upholsteryOption = find(options, { name: 'Leather' });
  } else if (partSku.match(/^WS[0-9]*/i)) {
    upholsteryOption = find(options, { name: 'Wood Stain' });
  } else if (
    partSku.match(/^W[0-9]*/i) &&
    !(partSku.match(/^wthrdk$/i) || partSku.match(/^white$/i))
  ) {
    upholsteryOption = find(options, { name: 'Wood' });
  } else if (
    partSku.match(/QN|FLL|ESNK|CK|EK|TWN/i) &&
    !partSku.match(/^black$/i)
  ) {
    upholsteryOption = find(options, { name: 'Bed Size' });
  } else if (partSku.match(/LAF|SACAFL|LFB|RAF|SACAFR|RFB|SCHR|SCHL/i)) {
    upholsteryOption = find(options, { name: 'Orientation' });
  } else if (partSku.match(/LGS/i)) {
    upholsteryOption = find(options, { name: 'Leg Option' });
  } else if (partSku.match(/RUG/i)) {
    upholsteryOption = find(options, { name: 'Size' });
  } else if (partSku.match(/MATT/i)) {
    upholsteryOption = find(options, { name: 'Mattress' });
  } else if (partSku.match(/NA|BNSCSHN/)) {
    upholsteryOption = find(options, { name: 'Optional Cushion' });
  }
  if (!upholsteryOption) {
    [upholsteryOption, optionValue] = checkInAllOptionsV2(options, partSku);
  }

  if (upholsteryOption) {
    optionTypeId = upholsteryOption.id;
    optionName = upholsteryOption.name;
    finalOptionValue =
      optionValue ||
      find(upholsteryOption.values, {
        sku: `-${partSku}`,
      });
  }
  return [finalOptionValue, optionTypeId, optionName];
}

export const constructFabricUrl = (
  fabricName,
  optionTypeId = null,
  forPage = null,
  optionId = null
) => {
  if (!fabricName) return null;
  let finalFabricName = fabricName;
  if (forPage && forPage === 'shop-color' && optionId) {
    finalFabricName = `${optionId}-${fabricName
      .trim()
      .toLowerCase()
      .split(' ')
      .join('-')}`;
  } else {
    finalFabricName = fabricName.trim().toLowerCase();
  }
  if (optionTypeId === WOOD_PRODUCT_OPTION_ID) {
    finalFabricName = fabricName;
  }
  return {
    transparent_config_image: addDomainToPath(
      `${FABRIC_IMG_URL}${finalFabricName}.jpg`
    ),
  };
};

export function getDefaultOptionData(options, displayOptionImages = false) {
  let finalValidProductOptionIds = [
    ...validPrimaryProductIds,
    SHELF_FINISH_PRODUCT_OPTION_ID,
    SIZE_PRODUCT_OPTION_ID,
    RUGS_SIZE_PRODUCT_OPTION_ID,
  ];
  if (displayOptionImages) {
    finalValidProductOptionIds = [...VALID_HERO_DISPLAY_PRODUCT_OPTIONS];
  }
  const primaryOption = options.find(optionValue =>
    finalValidProductOptionIds.includes(optionValue.id)
  );

  const woodStainOption = find(options, { id: WOOD_STAIN_PRODUCT_OPTION_ID });
  const woodOption = find(options, { id: WOOD_PRODUCT_OPTION_ID });
  let woodStainSku = '';
  let woodSku = '';
  let optionName = '';
  let finalSku = '';
  let woodDefaultOptionValue = null;
  if (woodStainOption) {
    const woodStainOptionValue =
      woodStainOption.values.length === 1 || !woodStainOption.default_value
        ? woodStainOption.values[0]
        : find(woodStainOption.values, {
            id: parseInt(woodStainOption.default_value, 10),
          });
    woodStainSku = woodStainOptionValue?.sku || '';
  }
  if (woodOption) {
    woodDefaultOptionValue = find(woodOption.values, {
      id: parseInt(woodOption.default_value, 10),
    });
    if (!woodDefaultOptionValue) {
      const woodOptionValue = woodOption.values[0];
      woodDefaultOptionValue = woodOptionValue;
    }
    woodSku += woodDefaultOptionValue?.sku || '';
  }
  if (!primaryOption) {
    if (woodSku) {
      finalSku += woodSku;
    }
    return { finalSku, optionName, optionData: woodDefaultOptionValue };
  }
  let finalPrimaryOptionValue = find(primaryOption.values, {
    id: parseInt(primaryOption.default_value, 10),
  });

  if (!finalPrimaryOptionValue) {
    const primaryOptionValue = primaryOption.values[0];
    finalPrimaryOptionValue = primaryOptionValue;
  }
  finalSku = finalPrimaryOptionValue?.sku || '';
  optionName = finalPrimaryOptionValue?.value || '';
  if (
    [FABRIC_PRODUCT_OPTION_ID, LEATHER_PRODUCT_OPTION_ID].includes(
      primaryOption.id
    )
  ) {
    finalSku += woodStainSku || woodSku || '';
  }

  return { finalSku, optionName, optionData: finalPrimaryOptionValue };
}

export function constructHeroImageURL(
  product,
  options = [],
  sku,
  count = 1,
  forCategory = false,
  displayOptionImages = false
) {
  let woodSku = '';
  const finalValidPrimaryIds = displayOptionImages
    ? VALID_HERO_DISPLAY_PRODUCT_OPTIONS
    : validPrimaryProductIds;
  const primaryOption = options.find(optionVal =>
    finalValidPrimaryIds.includes(optionVal.id)
  );

  woodSku = find(options, { id: WOOD_PRODUCT_OPTION_ID });

  let { optionName, finalSku } =
    options.length && primaryOption
      ? getDefaultOptionData(options, displayOptionImages)
      : { optionName: '', finalSku: '' };

  if (sku && (!!primaryOption || !!woodSku)) {
    finalSku = sku;
    if (
      [
        FABRIC_PRODUCT_OPTION_ID,
        LEATHER_PRODUCT_OPTION_ID,
        GLASS_PRODUCT_OPTION_ID,
      ].includes(primaryOption?.id)
    ) {
      const optionSkus = sku.split('-').filter(sk => !!sk);
      optionName = find(primaryOption.values, {
        sku: `-${optionSkus[0]}`,
      });

      optionName = optionName ? optionName.value : '';
    } else {
      optionName = '';
    }
  }

  return buildFinalHeroImageURL(
    product.name,
    product.id,
    finalSku,
    optionName,
    count,
    forCategory
  );
}

export const convertOptionsToNewPDPFormat = options => {
  if (!options?.length) {
    return [];
  }

  const updatedOptions = options.map(option => {
    let updatedOptionValues = null;
    if (option.values?.[0]?.option_values?.length) {
      updatedOptionValues = option.values[0].option_values.filter(val => !!val);
    } else if (option.values?.length) {
      updatedOptionValues = option.values.filter(val => !!val);
    }

    return {
      ...option,
      values: updatedOptionValues,
    };
  });
  return updatedOptions;
};

export const updateOptionsWithInactive = ({ options, inactiveOptions }) => {
  if (!options || !inactiveOptions) return null;
  const newOptions = convertOptionsToNewPDPFormat(options);
  const updatedOptions = newOptions.map(productOption => {
    const updatedProductOption = cloneDeep(productOption);
    inactiveOptions.forEach(inactiveOpt => {
      if (inactiveOpt && inactiveOpt.fk_option_id === updatedProductOption.id) {
        if (
          updatedProductOption.values.findIndex(
            opt => opt.id === inactiveOpt.id
          ) < 0
        ) {
          updatedProductOption.values.push(inactiveOpt);
        }
      }
    });
    return updatedProductOption;
  });

  return updatedOptions;
};

function findOptionForPartSku(options, type, partSku) {
  const typeDetails = find(options, { name: type }, false);
  if (!typeDetails) {
    return '';
  }

  let option = null;

  option = find(typeDetails.values, {
    sku: `-${partSku}`,
  });

  // return the option name which is converted to lowercase and joined with joinWith value passed or _.
  return option;
}

export function findOptionCost(options, type, partSku) {
  const option = findOptionForPartSku(options, type, partSku);
  return option?.additional_cost ?? 0;
}

export function getAdditionalCostForSKU(sku, options) {
  if (!(sku && options)) {
    return 0;
  }
  let addlCost = 0;
  const allOptionTypes = options.map(opt => opt.name);
  const skuArr = sku.split('-').slice(1);
  skuArr.forEach(partSku => {
    allOptionTypes.forEach(opt => {
      addlCost += findOptionCost(options, opt, partSku);
    });
  });

  return addlCost;
}

export function getExcludeFromSaleForSKU(sku, options) {
  if (!(sku && options)) {
    return 0;
  }
  let excludeFromSale = false;
  const allOptionTypes = options.map(opt => opt.name);
  const skuArr = sku.split('-').slice(1);
  skuArr.forEach(partSku => {
    allOptionTypes.forEach(opt => {
      const option = findOptionForPartSku(options, opt, partSku);
      if (option?.exclude_from_sale) {
        excludeFromSale = option.exclude_from_sale;
      }
    });
  });

  return excludeFromSale;
}

export function processVariantSKU(sku, options, listName) {
  if (sku?.length <= 1) {
    return '';
  }

  // fabric,woodStain,wood,bedSize,Orientation,rugs,leather,legOption,mattress,optionalCushion
  const skuArr = sku.split('-').slice(1);

  const urlSuffix = {};
  const titlesArr = [];
  const optionForFav = {};
  for (let i = 0; i < skuArr.length; i++) {
    const [
      finalOptionValue,
      optionTypeId,
      optionName,
    ] = findOptionAndTypeFromSkuV2({
      options,
      partSku: skuArr[i],
    });

    const formattedName = finalOptionValue?.value
      ? finalOptionValue.value
          .toLowerCase()
          .split(' ')
          .join('_')
      : '';

    if (
      finalOptionValue &&
      [
        FABRIC_PRODUCT_OPTION_ID,
        LEATHER_PRODUCT_OPTION_ID,
        WOOD_PRODUCT_OPTION_ID,
      ].includes(optionTypeId)
    ) {
      optionForFav.optionName = finalOptionValue.value;
      optionForFav.optionId = finalOptionValue.id;
      optionForFav.optionType = optionName;
    }

    if (formattedName) {
      const urlParam =
        PRODUCT_OPTION_URL_SUFFIX[optionTypeId] ||
        optionName.charAt(0).toLowerCase() +
          optionName.slice(1).replace(/\s/g, '');

      urlSuffix[urlParam] = formattedName;
      titlesArr.push(formattedName);
    }
  }
  if (listName === 'Clearance') {
    // also add the stockVariant.
    urlSuffix.stockVariant = sku;
  }

  return {
    url: `?${queryString.stringify(urlSuffix, { sort: false })}`,
    optionForFav,
    title: titlesArr
      .slice(0, 3)
      .join(' - ')
      .replace(/_/g, ' ')
      .replace(/(?:^|\s|_)\S/g, match => match.toUpperCase()),
  };
}

export function getFabricOrLeatherFromVariantSKU(sku, options) {
  const skuArr = sku.split('-');
  let upholstery = '';
  const leatherOrFabricOption = options.find(option =>
    [FABRIC_PRODUCT_OPTION_ID, LEATHER_PRODUCT_OPTION_ID].includes(option.id)
  );

  if (!leatherOrFabricOption) {
    return upholstery;
  }
  for (let idx = 1; idx < skuArr.length; idx++) {
    upholstery = leatherOrFabricOption.values.find(
      optVal => optVal.sku === `-${skuArr[idx]}`
    );
    if (upholstery) {
      upholstery = upholstery.value
        .trim()
        .toLowerCase()
        .split(' ')
        .join('-');
      break;
    }
  }
  return upholstery;
}

export function findOptionValueName({ options, id, partSku, joinWith = '_' }) {
  const option = find(options, { name: id }, false);
  if (!option) {
    return '';
  }

  const optionName = find(option.values, {
    sku: `-${partSku}`,
  });

  return optionName
    ? optionName.value
        .trim()
        .toLowerCase()
        .split(' ')
        .join(joinWith)
    : '';
}

export function getOptionValueByNameAndOptionType({
  optionType,
  optionValueName,
  options,
}) {
  if (!options?.length) return null;

  const selectedOption = find(options, {
    name: optionType,
  });
  if (!selectedOption) {
    return null;
  }
  const upholstery = optionValueName
    ? find(selectedOption.values, {
        value: optionValueName,
      })
    : null;

  return upholstery;
}

export function getProductPrimaryOptionId(options = []) {
  if (!options?.length) return null;

  let productOptionTypeId = null;
  const primaryProductOption = options.find(optionVal =>
    validPrimaryProductIds.includes(parseInt(optionVal.id, 10))
  );

  if (!primaryProductOption) {
    if (find(options, { id: WOOD_PRODUCT_OPTION_ID })) {
      productOptionTypeId = WOOD_PRODUCT_OPTION_ID;
    }
    return productOptionTypeId;
  }

  return primaryProductOption.id;
}

export function getProductPrimaryOption(options = []) {
  if (!options?.length) return null;

  let primaryProductOption = options.find(optionVal =>
    validPrimaryProductIds.includes(parseInt(optionVal.id, 10))
  );

  if (!primaryProductOption) {
    primaryProductOption = find(options, { id: WOOD_PRODUCT_OPTION_ID });
  }

  return primaryProductOption;
}

export const getIsProductConfigurable = options => {
  if (!options?.length) {
    return false;
  }

  const primaryOptionId = getProductPrimaryOptionId(options);
  if (!primaryOptionId) {
    return false;
  }
  const primaryOption = options.find(optVal => optVal.id === primaryOptionId);
  if (!primaryOption || primaryOption?.values?.length === 1) {
    return false;
  }
  return true;
};

export const getProductOptionById = (options, optionId) => {
  if (!options?.length || !optionId) {
    return null;
  }

  return options.find(
    optionValue => parseInt(optionValue.id, 10) === parseInt(optionId, 10)
  );
};

export const getFirstNOptionValues = (option, count) => {
  if (!option?.values?.length || !count) {
    return [];
  }
  const finalValues = [];
  for (let i = 0; i < option.values.length; i++) {
    if (i > count) {
      break;
    }
    finalValues.push(option.values[i]);
  }
  return finalValues;
};

export const sortOptionInPlaceByPopularOrder = option => {
  if (!option?.values?.length) {
    return [];
  }
  option.values.sort((val1, val2) => {
    if (val1.popularOrder < val2.popularOrder) {
      return -1;
    }
    if (val1.popularOrder > val2.popularOrder) {
      return 1;
    }
    return 0;
  });
  return option;
};

export function getValidOptionTypeForImage(options) {
  if (!options?.length) {
    return null;
  }

  const optionType = options.find(option =>
    ['Fabric', 'Leather', 'Wood'].includes(option.name)
  );

  return optionType?.name;
}

export function getValidOptionTypeIdForHeroOptionImage(options) {
  if (!options?.length) {
    return [];
  }

  const optionType = options.find(option =>
    [
      FABRIC_PRODUCT_OPTION_ID,
      LEATHER_PRODUCT_OPTION_ID,
      WOOD_PRODUCT_OPTION_ID,
      FRAME_PRODUCT_OPTION_ID,
      FINISH_PRODUCT_OPTION_ID,
      SIZE_PRODUCT_OPTION_ID,
      GLASS_PRODUCT_OPTION_ID,
    ].includes(parseInt(option.id, 10))
  );

  return [optionType?.name, optionType?.id];
}

export function getHeroImageSkuFromSelectedOptions(selectedOptionsExtended) {
  if (isEmpty(selectedOptionsExtended)) {
    return '';
  }
  const sku = Object.keys(selectedOptionsExtended)
    .filter(
      optionIdKey =>
        +optionIdKey === FABRIC_PRODUCT_OPTION_ID ||
        +optionIdKey === LEATHER_PRODUCT_OPTION_ID ||
        +optionIdKey === WOOD_STAIN_PRODUCT_OPTION_ID ||
        +optionIdKey === WOOD_PRODUCT_OPTION_ID ||
        +optionIdKey === FRAME_PRODUCT_OPTION_ID ||
        +optionIdKey === FINISH_PRODUCT_OPTION_ID ||
        +optionIdKey === SIZE_PRODUCT_OPTION_ID ||
        +optionIdKey === GLASS_PRODUCT_OPTION_ID ||
        +optionIdKey === WALLPAPER_COLOR_PRODUCT_OPTION_ID
    )
    .map(optionIdKey => selectedOptionsExtended[optionIdKey].value.sku)
    .join('');
  return sku;
}

export function getDefaultWoodstainSKU(options = []) {
  let woodstainOptions = options.find(
    optVal => optVal.id === WOOD_STAIN_PRODUCT_OPTION_ID
  );
  if (!woodstainOptions) {
    woodstainOptions = options.find(
      optVal => optVal.id === WOOD_PRODUCT_OPTION_ID
    );
  }
  let defaultWoodstainSKU = '';
  if (woodstainOptions) {
    if (woodstainOptions.default_value) {
      const defValue = woodstainOptions.values.find(
        optValue => optValue.id === parseInt(woodstainOptions.default_value, 10)
      );
      defaultWoodstainSKU = defValue ? defValue.sku : '';
    }
    if (!defaultWoodstainSKU && woodstainOptions.values?.length) {
      defaultWoodstainSKU = woodstainOptions.values[0].sku;
    }
  }

  return defaultWoodstainSKU;
}

export function getDefaultSKUandOption(product, options) {
  let sku = '';
  let optionVal = '';
  const optionTypeId = getProductPrimaryOptionId(options);
  if (optionTypeId) {
    const upholsteryOption = find(options, {
      id: optionTypeId,
    });
    const defaultWoodstainSKU = getDefaultWoodstainSKU(options);
    if (product.defaultValue) {
      sku = product.defaultValue.sku;
      optionVal = product.defaultValue.value;
    } else if (upholsteryOption.default_value) {
      const defaultOption = upholsteryOption.values.find(
        v => v.id === +upholsteryOption.default_value
      );
      sku = defaultOption.sku;
      optionVal = defaultOption.value;
    } else if (upholsteryOption) {
      sku = upholsteryOption.values[0].sku;
      optionVal = upholsteryOption.values[0].value;
    }

    return { sku: `${sku + defaultWoodstainSKU}`, optionVal };
  }
  return { sku, optionVal };
}

export function getSKUForMaterialOrOptionId({
  options,
  forMaterial,
  optionID,
  optionTypeId,
  fabricData = [],
}) {
  if (!optionTypeId || (!forMaterial && !optionID) || !options?.length)
    return {};

  const upholsteryOptions = options.find(
    optionValue => +optionValue.id === +optionTypeId
  );

  if (!upholsteryOptions) {
    return {};
  }
  let upholstery = optionID
    ? find(upholsteryOptions.values, {
        id: optionID,
      })
    : upholsteryOptions.values.find(
        opt => opt.value.trim() === forMaterial.trim()
      );
  if (fabricData?.length) {
    const forMaterialValue = fabricData.find(
      opt => opt.option_value === forMaterial.trim()
    );
    if (forMaterialValue) {
      upholstery = {
        id: forMaterialValue.option_value_id,
        value: forMaterialValue.option_value,
        sku: forMaterialValue.option_sku,
        image: forMaterialValue.option_image,
        color: forMaterialValue.option_color,
        is_active: forMaterialValue.is_active,
      };
    }
  }
  if (!upholstery) return {};

  const defaultWoodstainSKU =
    optionTypeId !== WOOD_PRODUCT_OPTION_ID
      ? getDefaultWoodstainSKU(options)
      : '';

  const sku = upholstery.sku + defaultWoodstainSKU;
  return { sku, upholstery };
}

export const convertSkuToImageURLValidSku = ({
  upholsterySku,
  fullSku,
  options,
  optionTypeId,
}) => {
  if (!fullSku) {
    return '';
  }
  const skuToCheck = fullSku.replace(upholsterySku, '');
  let finalSku = upholsterySku;
  if (optionTypeId === WOOD_PRODUCT_OPTION_ID) {
    return finalSku;
  }
  const woodStainOrWoodOption = options.find(option =>
    [WOOD_PRODUCT_OPTION_ID, WOOD_STAIN_PRODUCT_OPTION_ID].includes(option.id)
  );
  if (!woodStainOrWoodOption) {
    return finalSku;
  }
  const partSkus = skuToCheck.split('-').filter(v => !!v);
  let woodstainOrWoodSkuFound = false;
  for (let i = 0; i < partSkus.length; i++) {
    if (
      woodStainOrWoodOption.values.findIndex(val =>
        val.sku.includes(partSkus[i])
      ) > -1
    ) {
      finalSku += `-${partSkus[i]}`;
      woodstainOrWoodSkuFound = true;
      break;
    }
  }
  // Use default woodstain or wood sku
  if (!woodstainOrWoodSkuFound && woodStainOrWoodOption.values?.length) {
    finalSku += `${woodStainOrWoodOption.values[0].sku}`;
  }
  return finalSku;
};

export const getValuesForSelectedOptions = (options, selectedOptions) => {
  let fabric;
  let leather;
  let wood;
  let woodStain;

  if (options?.length) {
    const woodObj = options.find(o => o.id === WOOD_STAIN_PRODUCT_OPTION_ID);
    const fabricObj = options.find(o => o.id === FABRIC_PRODUCT_OPTION_ID);
    const leatherObj = options.find(o => o.id === LEATHER_PRODUCT_OPTION_ID);
    const woodStainObj = options.find(o => o.id === WOOD_PRODUCT_OPTION_ID);

    if (fabricObj?.values) {
      fabric = fabricObj.values.find(
        v => v.id === selectedOptions[FABRIC_PRODUCT_OPTION_ID]
      );
    }

    if (leatherObj?.values) {
      leather = leatherObj.values.find(
        v => v.id === selectedOptions[LEATHER_PRODUCT_OPTION_ID]
      );
    }

    if (woodObj?.values) {
      wood = woodObj.values.find(
        v => v.id === selectedOptions[WOOD_STAIN_PRODUCT_OPTION_ID]
      );
    }

    if (woodStainObj?.values) {
      woodStain = woodStainObj.values.find(
        v => v.id === selectedOptions[WOOD_PRODUCT_OPTION_ID]
      );
    }
  }

  return { fabric, leather, wood, woodStain };
};

export const isOptionValueInOptions = ({
  optionValue,
  optionValueId,
  optionId,
  options,
}) => {
  if (!optionValue || !options?.length) return false;
  return options
    .find(o => o.id === optionId)
    ?.values?.find(
      ({ id, value }) => id === optionValueId || value === optionValue
    );
};

export const getProductUrl = product => {
  const attributes = lodashGet(product, 'attributes', {});
  const url_key = find(attributes, { code: 'url_key' });

  if (!url_key) {
    return '';
  }
  let { value: slug } = url_key;
  if (product.slug.endsWith('-modular')) {
    if (slug.startsWith('/modulars/')) {
      slug = slug.replace('/modulars', '');
    }
    slug = !slug.startsWith('/modular/') ? `/modular${slug}` : slug;
  }
  return slug;
};
