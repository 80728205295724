import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import parseJson from 'commons/parseJson';
import { getUserLocationWithLongKeys } from 'commons/location';
import userLocationAtom from './userLocationAtom';

const DEfAULT_USER_LOCATION = {};

const useUserLocation = () => {
  // This hook parses the value from the atom (which is a stringified object
  // with short keys), and returns the userLocation object with long keys.
  const shortLocationStringified = useAtomValue(userLocationAtom);

  const shortLocation = useMemo(() => {
    if (!shortLocationStringified) {
      return undefined;
    }

    return parseJson(shortLocationStringified, DEfAULT_USER_LOCATION);
  }, [shortLocationStringified]);

  const userLocation = useMemo(() => {
    if (!shortLocation) {
      return DEfAULT_USER_LOCATION;
    }

    return getUserLocationWithLongKeys(shortLocation);
  }, [shortLocation]);

  return userLocation;
};

export default useUserLocation;
