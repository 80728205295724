import { useEffect, useState } from 'react';
import isClient from 'commons/isClient';
import { logError } from 'commons/logger';

const usePostHogFeatureFlag = name => {
  const [value, setValue] = useState(null);

  const parsePostHogQuery = query => {
    try {
      const queryObject = JSON.parse(query);

      if (queryObject?.clear === true) {
        return false;
      }

      return queryObject;
    } catch (err) {
      logError(err, 'Unable to parse posthogOverrides query', {
        query,
      });

      // clear overrides in for any malformed query
      return false;
    }
  };

  useEffect(() => {
    if (!isClient()) return;

    const intervalId = setInterval(() => {
      if (!window.posthog) return;

      clearInterval(intervalId);

      const postHogOverrideQuery = new URLSearchParams(
        window.location.search
      ).get('posthogOverrides');

      // wait for feature flags to be available
      window.posthog.onFeatureFlags(() => {
        // override the values if the override query param exist
        if (
          postHogOverrideQuery &&
          postHogOverrideQuery !== '' &&
          postHogOverrideQuery !== '{}' &&
          window.posthog.featureFlags
        ) {
          window.posthog.featureFlags.override(
            parsePostHogQuery(postHogOverrideQuery)
          );
        }
        // feature flags are guaranteed to be available here
        if (window.posthog.isFeatureEnabled(name)) {
          const val = window.posthog.getFeatureFlag(name);
          if (val !== null) {
            setValue(val);
          }
        }
      });
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [name]);

  return value;
};

export default usePostHogFeatureFlag;
