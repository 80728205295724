// Handle quickship filter separately because we have a specific quickship
// property available for every product variant
const getIsProductShownForQuickshipFilter = ({ activeFilters, variants }) => {
  const isQuickshipFilterActive = activeFilters?.quickship?.length > 0;

  if (!isQuickshipFilterActive) {
    return true;
  }

  const productHasReadyToShipOptions = variants.some(
    ({ quickship }) => quickship
  );

  if (productHasReadyToShipOptions) {
    // TODO: If isQuickshipFilterActive then also filter variants to only show those that are ready to ship
    return true;
  }

  return false;
};

export default getIsProductShownForQuickshipFilter;
