import getFirstFocusableElement from './getFirstFocusableElement';

// This function is used to find the first focusable element contained within a
// specified parent element
const focusOnFirstElement = parentElement => {
  const firstFocusableElement = getFirstFocusableElement(parentElement);

  if (firstFocusableElement) {
    firstFocusableElement.focus();
  } else {
    // If there is no focusable element, then we should focus on the parent
    // element instead.
    parentElement?.focus();
  }
};

export default focusOnFirstElement;
