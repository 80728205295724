// Note: this is a temporary hook that is being used to standardize the process
// of setting user data in Apollo Client. This will be replaced within the next
// 2 weeks, once we update all instances to use Jotai-based state instead.

import useSetUser from 'global-state/user/useSetUser';
import { useCallback } from 'react';

const useResetUserData = () => {
  const setUser = useSetUser();

  const resetUserData = useCallback(() => {
    setUser(null);
  }, [setUser]);

  return resetUserData;
};

export default useResetUserData;
