const getKeyForFilter = filter => {
  const { id, isActive, options, value } = filter;

  if (id === 'search') {
    return value ? `search-${value}` : '';
  }

  if (id === 'widthRange') {
    return value ? `widthRange-${value}` : '';
  }

  if (id === 'quickship') {
    return isActive ? 'quickship' : '';
  }

  const activeOptions = options?.filter(option => option.isActive);
  const activeOptionIds = activeOptions?.map(option => option.id) ?? [];
  const activeOptionIdsString = activeOptionIds.join('&');

  return activeOptionIdsString ? `${id}-${activeOptionIdsString}` : '';
};

// Create a unique key for each product listing page filter/sort combination.
// This key can be used to easily detect when the filter/sort combination has
// changed so that any necessary callbacks can be triggered, e.g. to reset the
// selected upholstery variant in each product card.
const getFilterStateKey = ({ filters = [], sortOrder = '' }) => {
  const filtersKey =
    filters
      .map(getKeyForFilter)
      .filter(Boolean)
      .join('--') || 'no-filters';

  const filterStateKey = `${filtersKey}--${sortOrder}`;

  return filterStateKey;
};

export default getFilterStateKey;
