import { useCallback, useMemo, useRef, useState } from 'react';

import useProductListingWithFavorites from 'hooks/useProductListingData/useProductListingWithFavorites';
import useProductListingScrollPosition from './useProductListingScrollPosition';
import useLoadMoreProducts from './useLoadMoreProducts';
import useProductListingFiltering from './useProductListingFiltering';
import useProductListingQuery from './useProductListingQuery';
import useProductListingSorting from './useProductListingSorting';
import getFilterStateKey from './helpers/getFilterStateKey';
import useProductListingProductsOnDisplay from './useProductListingProductsOnDisplay';
import useProductVariantsToShow from './useProductVariantsToShow';

// This hook provides all data required to render a product listing page,
// including the logic required for Load More functionality. It provides the
// same interface as useQuery (query, queryOptions), and returns a single object
// that can be used as a context value.

// useMemo is used within this file and the nested hooks called here to ensure
// that the return value of this hook has a stable identity. This is necessary
// so that it can be used as a context value without causing unnecessary
// re-renders.

const useProductListingData = (query, queryOptions, options = {}) => {
  const { onlyShowValidFilters = false } = options;

  // Load the initial set of products, then load all products
  const {
    filters,
    isAllDataLoading,
    isInitialDataLoading,
    pageId,
    pageName,
    pageLongName,
    pageHtmlTitle,
    pageLongDescription,
    pageMetaDescription,
    products,
    totalProductCount,
    upholsteryOptions,
  } = useProductListingQuery(query, queryOptions);

  const [hasLoadMoreBeenClicked, setHasLoadMoreBeenClicked] = useState(false);

  // A ref is used here so that we can pass this ref into
  // useProductListingFiltering, before the callback function is returned from
  // useLoadMoreProducts and then assigned once available.
  const resetNumberOfProductsToShowRef = useRef(null);

  // Filter the products and provide callbacks for filtering
  const {
    activeFilterCount,
    activeFilters,
    filters: filtersWithActiveAndDisabledState,
    productCount,
    products: filteredProducts,
    resetFilters,
    setCustomFilter,
    toggleBooleanFilter,
    toggleFilterOption,
  } = useProductListingFiltering({
    filters,
    onlyShowValidFilters,
    products,
    resetNumberOfProductsToShowRef,
  });

  const productsWithVariantsToShow = useProductVariantsToShow({
    colorFilters: activeFilters?.color,
    products: filteredProducts,
  });

  // Sort the products and provide callbacks for sorting
  const { productsSorted, setSortOrder, sortOrder } = useProductListingSorting(
    productsWithVariantsToShow
  );

  const filteredTotalProductCount = !isAllDataLoading
    ? productsSorted.length
    : totalProductCount;

  // Limit the number of products shown to the user, and provide callback to
  // load more products
  const {
    loadAllProducts,
    loadMoreClickCount = 0,
    loadMoreProducts,
    productsToPreload,
    productsToShow,
    resetNumberOfProductsToShow,
    showLoadMoreButton,
  } = useLoadMoreProducts({
    products: productsSorted,
    totalProductCount: filteredTotalProductCount,
  });

  const productsToShowWithFavorites = useProductListingWithFavorites({
    products: productsToShow,
  });

  resetNumberOfProductsToShowRef.current = resetNumberOfProductsToShow;

  const loadMoreProductsAndShowSpinner = useCallback(() => {
    setHasLoadMoreBeenClicked(true);
    loadMoreProducts();
  }, [loadMoreProducts]);

  // Store current scroll position and restore position if user clicks back on
  // another page to return to the current page
  useProductListingScrollPosition({
    isAllDataLoading,
    isInitialDataLoading,
    loadAllProducts,
    loadMoreClickCount,
    loadMoreProducts,
    products: productsToShowWithFavorites,
    slug: queryOptions?.variables?.slug,
  });

  // If the user clicks on the Load More or Show All buttons and all data has
  // not yet completed loading then we should show a loading spinner.
  const showLoadMoreButtonSpinner =
    (hasLoadMoreBeenClicked && isAllDataLoading) ||
    (productsSorted?.length < 12 && isAllDataLoading);

  const filterStateKey = useMemo(
    () =>
      getFilterStateKey({
        filters: filtersWithActiveAndDisabledState,
        sortOrder,
      }),
    [filtersWithActiveAndDisabledState, sortOrder]
  );

  const { slug, productsOnDisplay } = useProductListingProductsOnDisplay();

  const productsToShowWithShowroomSlug = useMemo(
    () =>
      productsToShowWithFavorites.map(product => ({
        ...product,
        onDisplayInShowroomSlug: productsOnDisplay.includes(product.id)
          ? slug
          : undefined,
      })),
    [productsToShowWithFavorites, productsOnDisplay, slug]
  );

  // Merge data together into an object that can be used as a context value
  const productListingPageData = useMemo(
    () => ({
      activeFilterCount,
      filters: filtersWithActiveAndDisabledState,
      filterStateKey,
      loading: isInitialDataLoading,
      loadMoreClickCount,
      loadAllProducts,
      loadMoreProducts: loadMoreProductsAndShowSpinner,
      isAllDataLoading,
      // If all data has loaded, or initial data has loaded and there are no
      // active filters, then we know the final product count
      isFinalProductCountKnown:
        !isAllDataLoading || (!isInitialDataLoading && activeFilterCount === 0),
      pageHtmlTitle,
      pageId: parseInt(pageId, 10),
      pageLongDescription,
      pageLongName,
      pageMetaDescription,
      pageName: pageName ?? '',
      productCount,
      products: productsToShowWithShowroomSlug,
      productsToPreload,
      resetFilters,
      setCustomFilter,
      setSortOrder,
      showLoadMoreButton: showLoadMoreButton && !isInitialDataLoading,
      showLoadMoreButtonSpinner,
      sortOrder,
      toggleBooleanFilter,
      toggleFilterOption,
      totalProductCount: filteredTotalProductCount,
      upholsteryOptions,
    }),
    [
      activeFilterCount,
      filteredTotalProductCount,
      filterStateKey,
      filtersWithActiveAndDisabledState,
      isAllDataLoading,
      isInitialDataLoading,
      loadAllProducts,
      loadMoreClickCount,
      loadMoreProductsAndShowSpinner,
      pageHtmlTitle,
      pageId,
      pageLongDescription,
      pageLongName,
      pageMetaDescription,
      pageName,
      productCount,
      productsToPreload,
      resetFilters,
      setCustomFilter,
      setSortOrder,
      showLoadMoreButton,
      showLoadMoreButtonSpinner,
      sortOrder,
      toggleBooleanFilter,
      toggleFilterOption,
      upholsteryOptions,
      productsToShowWithShowroomSlug,
    ]
  );

  return productListingPageData;
};

export default useProductListingData;
