import { clearUserTokenCookies } from 'commons/localStorage';

import useResetUserData from './useResetUserData';

const useLogoutClient = () => {
  const resetUserData = useResetUserData();

  const logoutClient = () => {
    resetUserData();
    clearUserTokenCookies();
  };

  return logoutClient;
};

export default useLogoutClient;
