import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import useClosestStoreZipcode from 'hooks/useClosestStoreZipcode';
import useSuspenseQuery from 'commons/useSuspenseQuery';
import { GET_CUSTOM_LIST_PRODUCT_DATA } from 'mocks/queries';
import { storeUrlSlugMapping } from 'commons/ShowroomStaticData';

const useProductListingProductsOnDisplay = () => {
  const location = useLocation();
  const isShowroomPage = location?.pathname.split('/').includes('showrooms');
  const closestStoreLocation = useClosestStoreZipcode();
  const closestStoreUrl = closestStoreLocation?.storeLocation?.url || null;

  const { data: showroomDisplayData } = useSuspenseQuery(
    GET_CUSTOM_LIST_PRODUCT_DATA,
    {
      variables: { slug: storeUrlSlugMapping[closestStoreUrl]?.slug },
      skip: isShowroomPage || !storeUrlSlugMapping[closestStoreUrl]?.slug,
    }
  );

  const productsOnDisplay = useMemo(
    () =>
      showroomDisplayData?.getCustomListProductData?.products_list
        ?.map(item => item.id)
        .filter(Boolean) || [],
    [showroomDisplayData]
  );

  return {
    slug: storeUrlSlugMapping[closestStoreUrl]?.slug,
    productsOnDisplay,
  };
};

export default useProductListingProductsOnDisplay;
