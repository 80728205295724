import { USER_LOCATION_COOKIE_KEY } from 'commons/constants';
import atomWithCookieStorage from 'global-state/helpers/atomWithCookieStorage';

// Note: since this value is stored in a cookie we need to store it as a
// stringified object, so we use '{}' as the initial value instead of {}
const INITIAL_VALUE = '{}';

// To minimize the size of the userLocation cookie, we store this data as a
// stringified object with short keys.

// The full userLocation object with long keys has this format:
// {
//   "zip":"90210",
//   "city":"Beverly Hills",
//   "state":"California",
//   "stateShortName":"CA",
//   "country":"United States"
// }

// While the same object with short keys is stringified and stored like this:
// {"zc":"90210","ct":"Beverly Hills","st":"California","sc":"CA","co":"United States"}

// The useSetUserLocation hook converts the received object of long keys to
// short keys and stringifies it before storing that data in the atom. The
// useUserLocation hook parses the stored data, and returns a userLocation
// object with long keys.
const userLocationAtom = atomWithCookieStorage(
  USER_LOCATION_COOKIE_KEY,
  INITIAL_VALUE
);

export default userLocationAtom;
