import { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import isCartOpenAtom from './isCartOpenAtom';

const useToggleCart = () => {
  const setIsCartOpen = useSetAtom(isCartOpenAtom);

  const toggleCart = useCallback(() => {
    setIsCartOpen(isCartOpen => !isCartOpen);
  }, [setIsCartOpen]);

  return toggleCart;
};

export default useToggleCart;
