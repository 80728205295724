const addIsActiveStateToFilter = ({ filter, activeFilters }) => {
  if (filter.id === 'quickship') {
    return {
      ...filter,
      isActive: !!activeFilters[filter.id]?.length,
    };
  }

  if (filter.id === 'search' || filter.id === 'widthRange') {
    return {
      ...filter,
      isActive: !!activeFilters[filter.id]?.length,
      value: activeFilters[filter.id]?.[0] ?? null,
    };
  }

  return {
    ...filter,
    options: filter.options?.map(option => ({
      ...option,
      isActive: activeFilters[filter.id]?.includes(option.id),
    })),
  };
};

export default addIsActiveStateToFilter;
