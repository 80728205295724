import { useMemo } from 'react';
import { isEmpty } from 'lodash';

import usePDPBasicDetails from 'components/consumer/PDP/state/context/usePDPBasicDetails';
import useAllProductClearanceStock from '../useAllProductClearanceStock';

const usePDPOptionsClearanceData = (
  id = null,
  slug = null,
  hookOptions = {}
) => {
  const { delayLoad = false, skip = false, defaultValue = {} } = hookOptions;

  const {
    loading: clearanceLoading,
    data: clearanceData,
  } = useAllProductClearanceStock();

  const {
    loading: basicLoading,
    error: basicError,
    data: basicData,
  } = usePDPBasicDetails(id, slug, {
    skipPDPStateHook: false,
    delayLoad,
  });

  const optionsClearanceData = useMemo(
    () =>
      !isEmpty(clearanceData) && basicData?.id
        ? clearanceData[basicData.id]
        : {},
    [clearanceData, basicData]
  );
  const dataLoading = useMemo(() => clearanceLoading || basicLoading, [
    clearanceLoading,
    basicLoading,
  ]);
  const errorFound = useMemo(() => basicError, [basicError]);

  if (skip) {
    return defaultValue;
  }

  return {
    loading: dataLoading,
    error: errorFound,
    data: {
      optionsClearanceData,
    },
  };
};

export default usePDPOptionsClearanceData;
