// This is a wrapper to allow us to parse a JSON string without throwing an
// error
const { logError } = require('./logger');

const parseJson = (jsonString, defaultReturnValue) => {
  if (!jsonString) {
    return defaultReturnValue;
  }

  try {
    const json = JSON.parse(jsonString);
    return json;
  } catch (e) {
    logError('Error parsing JSON data', e);
    return defaultReturnValue;
  }
};

module.exports = parseJson;
