import isClient from 'commons/isClient';
import scrollTo from 'commons/scrollTo';

const scrollToTop = () => {
  if (isClient()) {
    scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
};

export default scrollToTop;
