import { useEffect, useState } from 'react';
import { useSessionStorage } from 'react-use';
import { useHistory } from 'react-router-dom';

import useProductListingTrackScrollPosition from './useProductListingTrackScrollPosition';
import useProductListingRestoreScrollPosition from './useProductListingRestoreScrollPosition';

const useProductListingScrollPosition = ({
  isAllDataLoading,
  isInitialDataLoading,
  loadMoreClickCount,
  loadAllProducts,
  loadMoreProducts,
  products = [],
  slug,
}) => {
  const [
    hasScrollPositionBeenRestored,
    setHasScrollPositionBeenRestored,
  ] = useState(false);

  const history = useHistory();

  const lastProductPath = products[products.length - 1]?.path;

  useEffect(() => {
    // POP indicates that the user navigated to the page via either the Forward
    // or Back actions within the browser.
    // If the user navigated to this page any other way, then we should not
    // restore the scroll position, but we should mark it as restored so that
    // we can start tracking the scroll position.
    if (history.action !== 'POP') {
      setHasScrollPositionBeenRestored(true);
    }
  }, [history?.action]);

  const [
    {
      scrollPosition: previousScrollPosition = 0,
      loadMoreClickCount: previousLoadMoreClickCount = 0,
      lastProductPath: previousLastProductPath,
    },
    setScrollDataInSessionStorage,
  ] = useSessionStorage(`product-listing-page--${slug}`, {
    scrollPosition: 0,
    loadMoreClickCount: 0,
  });

  useProductListingTrackScrollPosition({
    hasScrollPositionBeenRestored,
    lastProductPath,
    loadMoreClickCount,
    previousScrollPosition,
    setScrollDataInSessionStorage,
  });

  useEffect(() => {
    // If we have no previous scroll position, or previous scroll position is 0,
    // then we don't need to restore scroll position.
    if (!previousScrollPosition) {
      setHasScrollPositionBeenRestored(true);
    }
  }, [previousScrollPosition, setHasScrollPositionBeenRestored]);

  // If user has clicked on Load More button when previously viewing page, wait
  // for all data to complete loading, otherwise just wait for initial data to
  // complete loading
  const isLoadingComplete =
    previousLoadMoreClickCount === -1 || previousLoadMoreClickCount > 0
      ? !isAllDataLoading
      : !isInitialDataLoading;

  useProductListingRestoreScrollPosition({
    isLoadingComplete,
    loadAllProducts,
    loadMoreProducts,
    previousLastProductPath,
    previousLoadMoreClickCount,
    previousScrollPosition,
    setHasScrollPositionBeenRestored,
    // Once scroll position has been restored, skip this hook
    skip: hasScrollPositionBeenRestored,
  });
};

export default useProductListingScrollPosition;
