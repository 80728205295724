import md5 from 'md5';

import {
  clearTempUserIdCookie,
  setUserAccessTokenCookie,
  setUserCookie,
  setUserRefreshTokenCookie,
} from 'commons/localStorage';
import useUserRawValue from 'global-state/user/useUserRawValue';
import useSetUser from 'global-state/user/useSetUser';
import useIdentifyFacebookUser from './useIdentifyFacebookUser';
import useRefetchAfterAuthChange from './useRefetchAfterAuthChange';

const useSetAuthTokenAndUser = () => {
  const userPrevious = useUserRawValue();
  const setUser = useSetUser();
  const identifyFacebookUser = useIdentifyFacebookUser();
  const refetchAfterAuthChange = useRefetchAfterAuthChange();

  const setAuthTokenAndUser = async (
    {
      access_token,
      email,
      first_name,
      group_id,
      id,
      is_designer,
      is_impersonate,
      last_name,
      refresh_token,
      user_discount,
    },
    shouldRefetch = true
  ) => {
    const user = {
      ...(userPrevious ?? {}),
      email,
      first_name,
      group_id,
      id,
      is_designer,
      is_impersonate: !!is_impersonate,
      last_name,
      user_discount,
    };

    // Remove temp user data
    clearTempUserIdCookie();

    setUserAccessTokenCookie(access_token);
    setUserRefreshTokenCookie(refresh_token);

    if (access_token) {
      setUserCookie({
        ...user,
        customerId: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        hashedEmail: user.email ? md5(user.email) : '',
      });
    }

    setUser(user);

    identifyFacebookUser({
      email,
      firstName: first_name,
      lastName: last_name,
      userId: id,
    });

    // Note: we deliberately do not await this async function so that other
    // post-sign-in steps can continue while refetches occur in parallel
    if (shouldRefetch) {
      refetchAfterAuthChange();
    }
  };

  return setAuthTokenAndUser;
};

export default useSetAuthTokenAndUser;
