import { gql } from '@apollo/client';

export const GET_PRODUCT_OPTIONS_LEAD_TIMES = gql`
  query getProductOptionsLeadTime(
    $id: Int!
    $zipcode: String
    $options: String
  ) {
    getProductOptionsLeadTime(id: $id, zipcode: $zipcode, options: $options) {
      lead_time
      variant_sku
      errors {
        code
        message
      }
    }
  }
`;
