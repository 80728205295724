import { useEffect, useState } from 'react';

const useOnOpen = (isOpen, onOpenCallback = () => {}) => {
  const [prevIsOpen, setPrevIsOpen] = useState();

  useEffect(() => {
    if (isOpen) {
      onOpenCallback();
    }

    setPrevIsOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen !== prevIsOpen) {
      if (isOpen) {
        onOpenCallback();
      }

      setPrevIsOpen(isOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, onOpenCallback]);
};

export default useOnOpen;
