const getSlugFromLabel = name =>
  name
    ?.toLowerCase()
    .trim()
    .replace('&', 'and')
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export default getSlugFromLabel;
