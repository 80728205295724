import { useCallback, useMemo, useRef } from 'react';
import qs from 'query-string';
import { omit } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

const decodeParams = paramsString => qs.parse(paramsString);
const encodeParams = params => qs.stringify(params);

const useQueryParams = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const queryParams = useMemo(() => decodeParams(search), [search]);

  // Use a ref to access the most recent queryParams value without causing a new
  // instance of setQueryParams to be created when the value changes
  const queryParamsRef = useRef(queryParams);
  queryParamsRef.current = queryParams;

  const setQueryParams = useCallback(
    (updatedParams, addToHistory = false) => {
      // By default, replace the current history entry so that the user doesn't
      // have to press Back many times to get to a previous page when this hook
      // is used for filter and sorting properties. If `addToHistory` is true,
      // push a new history entry instead.
      const pushOrReplace = addToHistory ? history.push : history.replace;

      // Find all null params so that we can remove them from the query string
      const nullParams = Object.entries(updatedParams)
        .filter(([, paramValue]) => paramValue === null)
        .map(([paramKey]) => paramKey)
        .filter(Boolean);

      // Spread the previous params first, so that all previous params are still
      // included unless they are are replaced or removed (via a null value) in
      // the updatedParams passed into this function.
      const newParams = omit(
        { ...queryParamsRef.current, ...updatedParams },
        nullParams
      );

      pushOrReplace(`${pathname}?${encodeParams(newParams)}`);
    },
    [history.push, history.replace, pathname]
  );

  return [queryParams, setQueryParams];
};

export default useQueryParams;
