const getIsProductShownForColorFilter = ({ activeFilters, variants }) => {
  const colorFilterActiveOptions = activeFilters?.color;

  if (!colorFilterActiveOptions?.length) {
    return true;
  }

  const isProductShownForColorFilter = colorFilterActiveOptions.some(color => {
    const isProductShownForColor = variants.some(
      ({ color: variantColor }) =>
        // By using color.includes(variantColor) we can match a color value such
        // as "beige/white" with a variantColor filter value such as "white".
        !!variantColor && color.includes(variantColor)
    );

    return isProductShownForColor;
  });

  return isProductShownForColorFilter;
};

export default getIsProductShownForColorFilter;
