import React from 'react';
import PropTypes from 'prop-types';
import { colorWhite } from 'styles/layout/colors';
import classNames from 'classnames';

const IconGreenguardGold = ({
  color = colorWhite,
  width = '50',
  height = '49',
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('pr-2 cursor-pointer', className)}
  >
    <path
      d="M9.63941 48.26C4.55941 48.26 0.399414 44.12 0.399414 39.06V9.61002C0.399414 4.54002 4.55941 0.400024 9.63941 0.400024H40.3294C45.4194 0.400024 49.5794 4.54002 49.5794 9.61002V39.06C49.5794 44.12 45.4194 48.26 40.3294 48.26H9.63941Z"
      fill="#8FD400"
      stroke="white"
      strokeWidth="0.8"
      strokeMiterlimit="10"
    />
    <path
      d="M0.299805 39.35H49.5798"
      stroke="white"
      strokeWidth="0.79"
      strokeMiterlimit="10"
    />
    <path
      d="M21.1701 45.45C20.2001 45.45 19.6201 44.88 19.6201 43.93C19.6201 42.98 20.1901 42.25 21.3001 42.25C21.6001 42.25 21.9001 42.3 22.1601 42.38L22.0701 42.76C21.8201 42.66 21.5401 42.61 21.2601 42.61C20.4201 42.61 20.0801 43.2 20.0801 43.86C20.0801 44.64 20.5101 45.08 21.2401 45.08C21.4501 45.08 21.6201 45.05 21.7901 45V43.82H22.2101V45.27C21.9001 45.39 21.5201 45.45 21.1801 45.45H21.1701Z"
      fill={color}
    />
    <path
      d="M24.1605 45.45C23.2205 45.45 22.7705 44.83 22.7705 43.88C22.7705 42.84 23.4405 42.23 24.2505 42.23C25.1905 42.23 25.6405 42.85 25.6405 43.8C25.6405 44.84 24.9705 45.44 24.1605 45.44V45.45ZM24.2105 42.61C23.6705 42.61 23.2305 43.04 23.2305 43.82C23.2305 44.6 23.5505 45.07 24.2105 45.07C24.7505 45.07 25.1905 44.64 25.1905 43.87C25.1905 43.1 24.8705 42.61 24.2105 42.61Z"
      fill={color}
    />
    <path
      d="M26.2305 45.4V42.28H26.6605V45.03H27.7805V45.4H26.2405H26.2305Z"
      fill={color}
    />
    <path
      d="M28.9804 45.42C28.6704 45.42 28.4204 45.4 28.1904 45.4V42.29C28.4304 42.29 28.7804 42.27 29.1204 42.27C30.1204 42.27 30.7104 42.74 30.7104 43.81C30.7104 44.88 29.9904 45.42 28.9704 45.42H28.9804ZM29.0804 42.62C28.9204 42.62 28.7304 42.62 28.6104 42.63V45.03C28.7304 45.05 28.9004 45.06 29.0204 45.06C29.7504 45.06 30.2604 44.68 30.2604 43.84C30.2604 43.05 29.8804 42.62 29.0704 42.62H29.0804Z"
      fill={color}
    />
    <path
      d="M24.7895 25.35C29.1895 25.35 32.8695 22.27 33.7995 18.15C31.1295 16.66 28.0595 15.8 24.7895 15.8C21.5195 15.8 18.4395 16.65 15.7695 18.15C16.6995 22.27 20.3795 25.35 24.7895 25.35Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.2894 14.68H25.6094V9.30005H26.8494V13.51H28.2894V14.68Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.7697 11.22C24.7697 12.2 24.0597 12.71 23.1297 12.71C22.1997 12.71 21.4697 12.21 21.4697 11.22V7.28003H22.6997V11.26C22.6997 11.54 22.8897 11.73 23.1197 11.73C23.4097 11.73 23.5397 11.49 23.5397 11.26V7.28003H24.7697V11.22Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.0703 15.0101C36.0703 21.2401 31.0203 26.2801 24.8003 26.2801C18.5803 26.2801 13.5303 21.2301 13.5303 15.0101C13.5303 8.79005 18.5703 3.74005 24.8003 3.74005C31.0303 3.74005 36.0703 8.79005 36.0703 15.0101ZM24.8003 5.76005C19.6903 5.76005 15.5503 9.90005 15.5503 15.0101C15.5503 20.1201 19.6903 24.2601 24.8003 24.2601C29.9103 24.2601 34.0503 20.1201 34.0503 15.0101C34.0503 9.90005 29.9103 5.76005 24.8003 5.76005Z"
      fill={color}
    />
    <path
      d="M22.9494 23.53C22.9494 22.14 23.4194 19.33 26.6194 18.28C23.6694 20.02 23.5694 22.81 23.5794 23.36L24.7894 23.15C26.2494 22.89 27.4394 21.5 27.4394 20.05V17.45L24.8194 17.91C23.3594 18.17 22.1094 19.63 22.1094 21.19V26.52C22.3294 26.6 22.7194 26.56 22.9494 26.61V23.54V23.53Z"
      fill="#8FD400"
    />
    <path
      d="M7.10934 34.76C5.61934 34.76 4.81934 33.92 4.81934 32.57C4.81934 31.1 5.69934 30.1 7.32934 30.1C7.79934 30.1 8.24934 30.18 8.60934 30.29L8.43934 31.09C8.09934 30.95 7.69934 30.88 7.27934 30.88C6.20934 30.88 5.75934 31.6 5.75934 32.46C5.75934 33.42 6.25934 33.98 7.19934 33.98C7.40934 33.98 7.59934 33.95 7.77934 33.9V32.32H8.64934V34.48C8.19934 34.66 7.63934 34.76 7.09934 34.76H7.10934Z"
      fill={color}
    />
    <path
      d="M11.7391 34.7L10.5191 32.83H10.4491V34.7H9.5791V30.17C9.9391 30.15 10.3591 30.14 10.8791 30.14C11.8791 30.14 12.5191 30.49 12.5191 31.41C12.5191 32.08 12.0391 32.56 11.4091 32.67C11.5391 32.84 11.6591 33.01 11.7691 33.17L12.8191 34.69H11.7391V34.7ZM10.7891 30.86C10.6591 30.86 10.5391 30.86 10.4491 30.88V32.19C10.5091 32.19 10.6291 32.2 10.7691 32.2C11.2891 32.2 11.6091 31.93 11.6091 31.51C11.6091 31.09 11.3391 30.86 10.7891 30.86Z"
      fill={color}
    />
    <path
      d="M13.4092 34.7V30.17H15.8692V30.9H14.2792V32.01H15.7892V32.72H14.2792V33.94H15.8692V34.69H13.4092V34.7Z"
      fill={color}
    />
    <path
      d="M16.7393 34.7V30.17H19.1993V30.9H17.6093V32.01H19.1193V32.72H17.6093V33.94H19.1993V34.69H16.7393V34.7Z"
      fill={color}
    />
    <path
      d="M22.6593 34.7001L21.2593 32.2101C21.0893 31.8801 20.9593 31.6101 20.8593 31.3301C20.8893 31.7301 20.9093 32.8301 20.9093 33.4801V34.7101H20.0693V30.1801H21.2193L22.5893 32.6101C22.7493 32.8901 22.9093 33.2201 23.0293 33.5201C22.9993 33.0601 22.9793 31.9501 22.9793 31.3201V30.1801H23.8193V34.7101H22.6693L22.6593 34.7001Z"
      fill={color}
    />
    <path
      d="M26.8095 34.76C25.3195 34.76 24.5195 33.92 24.5195 32.57C24.5195 31.1 25.3995 30.1 27.0295 30.1C27.4995 30.1 27.9495 30.18 28.3095 30.29L28.1395 31.09C27.7995 30.95 27.3995 30.88 26.9795 30.88C25.9095 30.88 25.4595 31.6 25.4595 32.46C25.4595 33.42 25.9595 33.98 26.8995 33.98C27.1095 33.98 27.2995 33.95 27.4795 33.9V32.32H28.3495V34.48C27.8995 34.66 27.3395 34.76 26.7995 34.76H26.8095Z"
      fill={color}
    />
    <path
      d="M31.0293 34.77C29.5993 34.77 29.2793 33.99 29.2793 33.13V30.17H30.1493V33.07C30.1493 33.64 30.3393 34.04 31.0893 34.04C31.7593 34.04 32.0393 33.76 32.0393 32.99V30.17H32.8993V32.94C32.8993 34.18 32.2193 34.77 31.0193 34.77H31.0293Z"
      fill={color}
    />
    <path
      d="M36.3989 34.7L36.0889 33.77H34.3689L34.0489 34.7H33.1689L34.7289 30.17H35.7589L37.3589 34.7H36.3889H36.3989ZM35.4089 31.74C35.3389 31.52 35.2789 31.32 35.2289 31.12C35.1789 31.32 35.1189 31.52 35.0589 31.74L34.5989 33.09H35.8889L35.4289 31.74H35.4089Z"
      fill={color}
    />
    <path
      d="M39.9989 34.7L38.7789 32.83H38.7089V34.7H37.8389V30.17C38.1989 30.15 38.6189 30.14 39.1389 30.14C40.1389 30.14 40.7789 30.49 40.7789 31.41C40.7789 32.08 40.2989 32.56 39.6689 32.67C39.7989 32.84 39.9189 33.01 40.0289 33.17L41.0789 34.69H39.9989V34.7ZM39.0489 30.86C38.9189 30.86 38.7989 30.86 38.7089 30.88V32.19C38.7689 32.19 38.8889 32.2 39.0289 32.2C39.5489 32.2 39.8689 31.93 39.8689 31.51C39.8689 31.09 39.5989 30.86 39.0489 30.86Z"
      fill={color}
    />
    <path
      d="M42.9397 34.72C42.4697 34.72 42.0597 34.7 41.6797 34.69V30.18C42.0397 30.17 42.5797 30.15 43.1397 30.15C44.5397 30.15 45.4297 30.77 45.4297 32.36C45.4297 33.95 44.4097 34.73 42.9397 34.73V34.72ZM43.0797 30.87C42.8797 30.87 42.6897 30.87 42.5497 30.89V33.94C42.6697 33.96 42.8497 33.97 43.0297 33.97C43.8797 33.97 44.4997 33.49 44.4997 32.41C44.4997 31.44 44.1197 30.86 43.0897 30.86L43.0797 30.87Z"
      fill={color}
    />
  </svg>
);

IconGreenguardGold.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

export default IconGreenguardGold;
