import { useAtomValue } from 'jotai';

import useIsBrowser from 'hooks/useIsBrowser';
import isZipCodeFromUserServiceableAtom from './isZipCodeFromUserServiceableAtom';

const DEFAULT_VALUE = true;

// Cookies cannot be read within the server-side render process in the existing
// Consumer app, so we should wait until the app mounts in the browser before
// returning a value. For consistency with the existing app, we follow the same
// process in the new Consumer app right now, but once the existing app has
// been replaced in production we can update this hook to use the value from
// cookies on the server too.
const useIsZipCodeFromUserServiceable = () => {
  const isBrowser = useIsBrowser();

  const isZipCodeFromUserServiceable = useAtomValue(
    isZipCodeFromUserServiceableAtom
  );

  if (!isBrowser) {
    return DEFAULT_VALUE;
  }

  return isZipCodeFromUserServiceable;
};

export default useIsZipCodeFromUserServiceable;
