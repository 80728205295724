import { useAtomValue } from 'jotai';

import { DEFAULT_WAREHOUSE_ID } from 'commons/constants';
import warehouseIdForUserAtom from './warehouseIdForUserAtom';

const useWarehouseIdForUser = () => {
  const warehouseIdForUser = useAtomValue(warehouseIdForUserAtom);
  return warehouseIdForUser ?? DEFAULT_WAREHOUSE_ID;
};

export default useWarehouseIdForUser;
