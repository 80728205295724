import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import getFirstFocusableElement from 'hooks/helpers/getFirstFocusableElement';
import { useIntersection } from 'react-use';
import useScreen from 'hooks/useScreen';
import { setReactTooltipValues } from './utils';

const HotspotRenderer = ({
  hotspot,
  showTooltip,
  onClick,
  root,
  rootRef,
  tooltipStatus,
  size = 'small',
  changeTooltipStatus,
  resetAllExceptOne,
  placeLeftOrRight,
  ...props
}) => {
  const tooltipRef = useRef(null);
  const hotspotTagIconRef = useRef(null);
  const [showHangTagIcon, setShowHangTagIcon] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { isMobile } = useScreen();

  const intersection = useIntersection(hotspotTagIconRef, {
    threshold: 0.1,
  });

  function useOutsideClickHideToolTip(ref) {
    useEffect(() => {
      // Function for click event
      function handleOutsideClickHideToolTip(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          ReactTooltip.hide(tooltipRef.current);
          setTooltipOpen(false);
        }
      }

      // Adding click event listener
      document.addEventListener('click', handleOutsideClickHideToolTip);
      return () =>
        document.removeEventListener('click', handleOutsideClickHideToolTip);
    }, [ref]);
  }

  useEffect(() => {
    // listen for changes
    if (intersection) {
      setShowHangTagIcon(intersection.isIntersecting);
    }
  }, [intersection]);

  useOutsideClickHideToolTip(tooltipRef);

  useEffect(() => {
    if (tooltipStatus) {
      ReactTooltip.show(tooltipRef.current);
      setTooltipOpen(true);
      setTimeout(() => {
        const tooltipElement = document.querySelector(
          '.hotspot-tooltip-wrapper'
        );

        const firstFocusableElement = getFirstFocusableElement(tooltipElement);

        if (firstFocusableElement) {
          firstFocusableElement.focus();
        }
      }, 20);
    } else {
      ReactTooltip.hide(tooltipRef.current);
      setTooltipOpen(false);
    }
  }, [tooltipStatus]);

  const placement =
    Math.round(parseInt(hotspot.left, 10)) < 20 ? 'right' : 'left';

  return (
    <div ref={hotspotTagIconRef}>
      <button
        disabled={props.fromMobileGrid}
        type="button"
        className={classNames(
          ' bg-brand-darker',
          'bg-transparent absolute z-[2] rounded-full cursor-pointer p-0 w-12 h-12 -ml-6 -mt-6 [transition:.3s] [transform-style:preserve-3d] border-2 border-solid border-transparent',
          'focus-visible:border-brand focus-visible:outline-0',
          '[&_svg]:w-4 [&_svg]:h-4 [&_svg]:text-white [&_svg]:z-[2] [&_svg]:absolute [&_svg]:left-0 [&_svg]:right-0 [&_svg]:top-0 [&_svg]:bottom-0 [&_svg]:m-auto [&_svg]:[backface-visibility:hidden]',
          {
            'animate-[fade-out_ease_3s] [animation-fill-mode:forwards] [animation-delay:5s]':
              showHangTagIcon && !props.isActive && !props.dontFadeTagIcon,
            '[&_svg]:w-5 [&_svg]:h-5': size === 'big',
            // tooltip styles
            '[transform:rotateY(180deg)]': tooltipOpen,
            '[transform:rotateY(0deg)] [&_.open-tooltip-icon]:hidden': !tooltipOpen,
          }
        )}
        data-xc="hotspot-renderer"
        ref={tooltipRef}
        aria-expanded={tooltipStatus ? 'true' : 'false'}
        data-tip={`${hotspot.product_id}${hotspot.product_config_url}`}
        data-place={placeLeftOrRight ? placement : undefined}
        onClick={e => {
          setReactTooltipValues('root', root || 'global');
          setReactTooltipValues('rootRef', rootRef);
          if (showTooltip) {
            if (tooltipStatus) {
              changeTooltipStatus(false);
            } else {
              resetAllExceptOne();
            }
          }
          if (onClick) {
            onClick(hotspot);
          }
          e.preventDefault();
          e.stopPropagation();
        }}
        style={
          !props.fromMobileGrid
            ? {
                left: hotspot.left,
                top: hotspot.top,
              }
            : {
                right: '0',
                top: '15%',
              }
        }
      >
        <div
          className={classNames(
            'absolute left-0 right-0 bottom-0 top-0 m-1.5 rounded-full [background:rgba(47,47,46,.42)]',
            'hover:[background:rgba(39,145,156,.9)]',
            {
              'm-0.5': size === 'big',
              '[background:rgba(39,145,156,.75)]': tooltipStatus,
            }
          )}
          aria-hidden
        >
          {/* need the open-tooltip-icon here so that it can be targeted by ReactTooltip integrations across the site */}
          <svg
            className="open-tooltip-icon"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m31.4193429 22.2582763-9.6776192-9.6776192c-.3230002-.3871047-.8383141-.5806571-1.3548667-.5806571h-6.4513332c-1.0972097 0-1.9355238.8383141-1.9355238 1.9355238v6.4513332c0 .5165526.1935524 1.0318665.5806571 1.3548667l9.6776192 9.6776192c.3871048.3871047.9036574.5806571 1.3548667.5806571s.9677619-.1935524 1.3548667-.5806571l6.4513332-6.4513332c.7742095-.710105.7742095-1.9996284 0-2.7097334zm-13.9442957-2.5332291c-1.2378239 0-2.25-1.0121761-2.25-2.25s1.0121761-2.25 2.25-2.25 2.25 1.0121761 2.25 2.25-1.0121761 2.25-2.25 2.25z"
              fill="currentColor"
              fillRule="evenodd"
              transform="translate(-12 -12)"
            />
          </svg>
          {!isMobile && (
            <svg
              className={classNames('transform-[rotateY(-180deg)] w-8 h-8', {
                'w-10 h-10': size === 'big',
              })}
              viewBox="0 0 28 28"
            >
              <path
                fill="currentColor"
                d="M15.4 14l3.3-3.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0L14 12.6l-3.3-3.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l3.3 3.3-3.3 3.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l3.3-3.3 3.3 3.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L15.4 14z"
              />
            </svg>
          )}
        </div>
      </button>
    </div>
  );
};

HotspotRenderer.propTypes = {
  hotspot: PropTypes.shape({
    left: PropTypes.number,
    product_id: PropTypes.string,
    product_config_url: PropTypes.string,
    top: PropTypes.number,
  }),
  showTooltip: PropTypes.bool,
  onClick: PropTypes.func,
  root: PropTypes.string,
  size: PropTypes.oneOf(['small', 'big']),
  rootRef: PropTypes.string,
  tooltipStatus: PropTypes.bool,
  changeTooltipStatus: PropTypes.func,
  resetAllExceptOne: PropTypes.func,
  placeLeftOrRight: PropTypes.bool,
  isActive: PropTypes.bool,
  dontFadeTagIcon: PropTypes.bool,
  fromMobileGrid: PropTypes.bool,
};

export default HotspotRenderer;
