import { useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { logError } from 'commons/logger';
import validateFormatZipCode from 'commons/validateFormatZipCode';
import { VALIDATE_ZIP_CODE } from 'mocks/queries';
import { ZIPCODE_ERROR_MESSAGES } from 'commons/constants';

const useIsZipCodeServiceable = zip => {
  const trimmedZipCode = zip?.trim?.();

  const isValidZipCodeFormat =
    !!trimmedZipCode && validateFormatZipCode(trimmedZipCode);

  const { data, error: queryError, loading, refetch: refetchQuery } = useQuery(
    VALIDATE_ZIP_CODE,
    {
      variables: {
        zip: trimmedZipCode,
      },
      skip: !isValidZipCodeFormat,
    }
  );

  // We provide a custom async refetch function to check if a specified zip
  // code is valid during form validation
  const refetch = useCallback(
    async updatedZipCode => {
      const updatedTrimmedZipCode = updatedZipCode?.trim();

      const updatedIsValidZipCodeFormat =
        !!updatedTrimmedZipCode && validateFormatZipCode(updatedTrimmedZipCode);

      if (!updatedIsValidZipCodeFormat) {
        return {
          error: ZIPCODE_ERROR_MESSAGES.ZIPCODE_IS_INVALID_FORMAT,
          isZipCodeServiceable: false,
        };
      }

      const {
        data: updatedData,
        error: updatedQueryError,
      } = await refetchQuery({
        zip: updatedTrimmedZipCode,
      });

      if (!!updatedTrimmedZipCode && updatedQueryError) {
        logError(updatedQueryError, 'Unexpected error in useIsValidZipCode');
      }

      const updatedIsZipCodeServiceable =
        updatedIsValidZipCodeFormat &&
        !updatedQueryError &&
        updatedData?.validateZipCode?.isValid;

      const updatedNonServiceableZipCodeError =
        updatedData?.validateZipCode?.errors?.[0]?.message;

      return {
        error: updatedQueryError
          ? ZIPCODE_ERROR_MESSAGES.UNEXPECTED_ERROR
          : undefined,
        nonServiceableZipCodeError: updatedNonServiceableZipCodeError,
        isZipCodeServiceable: updatedIsZipCodeServiceable,
      };
    },
    [refetchQuery]
  );

  const isLoading = !data && loading;

  useEffect(() => {
    if (!!trimmedZipCode && !isLoading && queryError) {
      logError(queryError, 'Unexpected error in useIsValidZipCode');
    }
  }, [queryError, isLoading, trimmedZipCode]);

  const isZipCodeServiceable = !!(
    isValidZipCodeFormat &&
    !queryError &&
    data?.validateZipCode?.isValid
  );

  // If this zip code is not serviceable, the server will return an error
  // message in the response.
  const nonServiceableZipCodeError =
    data?.validateZipCode?.errors?.[0]?.message;

  return {
    // When the user updates the zip code, the app displays non-serviceable
    // zip code errors separately from other errors so they are returned
    // separately here. In the UI, **both** of these errors must be handled.
    error: queryError ? ZIPCODE_ERROR_MESSAGES.UNEXPECTED_ERROR : undefined,
    nonServiceableZipCodeError,
    isZipCodeServiceable,
    loading: isLoading,
    refetch,
  };
};

export default useIsZipCodeServiceable;
