import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';

import { logError } from 'commons/logger';
import HotspotRenderer from './HotspotRenderer';
import { calculate } from './utils';

const exifText = 'LensModel';
const metadataKey = 'jb-hotspots';

const getUrl = src => {
  if (!src) {
    return null;
  }

  try {
    const url = new URL(src);
    return url;
  } catch (e) {
    return null;
  }
};

class HotspotWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.unmounted = false;
    this.initialTooltipStatus = {};
    this.state = {
      hotspots: [],
      tooltipStatus: {},
    };
    this.fetchHotspotData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.src !== this.props.src) {
      this.fetchHotspotData();
    }
    if (prevState.hotspots.length < 1 && this.state.hotspots.length > 0) {
      ReactTooltip.rebuild();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  fetchHotspotData = () => {
    const { src, crop, setHotspotData } = this.props;
    const tempURL = getUrl(src);

    if (!tempURL) {
      return;
    }

    tempURL.searchParams.set('fm', 'json');
    const jsonURL = tempURL.href;
    axios
      .get(jsonURL)
      .then(res => {
        let metadata;
        try {
          metadata = JSON.parse(get(res.data, `Exif.${exifText}`, '{}'));
        } catch (e) {
          metadata = {};
        }
        let hotspots = get(metadata, `${metadataKey}.value`, []);

        if (!isEmpty(crop)) {
          const originalSize = get(metadata, `${metadataKey}.image`, {});
          hotspots = hotspots
            .map(hotspot => {
              const { x, y, left, top } = hotspot;
              const original = {
                originalWidth: originalSize.width,
                originalHeight: originalSize.height,
                hotspotX: x,
                hotspotY: y,
                left,
                top,
              };
              const newHotspot = calculate(original, crop);
              if (newHotspot) {
                return {
                  ...hotspot,
                  x: newHotspot.hotspotX,
                  y: newHotspot.hotspotY,
                  left: newHotspot.left,
                  top: newHotspot.top,
                };
              }
              return null;
            })
            .filter(hotspot => !!hotspot);
        }
        if (!this.unmounted) {
          const tooltipStatus = hotspots
            .map(hotspot => hotspot.product_id)
            .reduce((result, productId) => {
              result[productId] = false;
              return result;
            }, {});
          this.initialTooltipStatus = tooltipStatus;
          this.setState(() => ({ hotspots, tooltipStatus }));
          if (setHotspotData) {
            setHotspotData(hotspots);
          }
        }
      })
      .catch(logError);
  };

  changeTooltipStatus = (tooltipId, value) => {
    this.setState(({ tooltipStatus }) => ({
      tooltipStatus: {
        ...tooltipStatus,
        [tooltipId]: value,
      },
    }));
  };

  resetAllExceptOne = tooltipId => {
    this.setState(() => ({
      tooltipStatus: {
        ...this.initialTooltipStatus,
        [tooltipId]: true,
      },
    }));
  };

  render() {
    const { hotspots, tooltipStatus } = this.state;
    const {
      src,
      setHotspotData,
      crop,
      placeLeftOrRight,
      ...props
    } = this.props;
    return (
      <>
        {hotspots.map(hotspot => (
          <HotspotRenderer
            {...props}
            key={`${hotspot.product_id}-${hotspot.key}`}
            hotspot={hotspot}
            tooltipStatus={tooltipStatus[hotspot.product_id]}
            resetAllExceptOne={() => this.resetAllExceptOne(hotspot.product_id)}
            changeTooltipStatus={() =>
              this.changeTooltipStatus(hotspot.product_id)
            }
            placeLeftOrRight={placeLeftOrRight}
          />
        ))}
      </>
    );
  }
}

HotspotWrapper.propTypes = {
  crop: PropTypes.shape({
    fx: PropTypes.number,
    fy: PropTypes.number,
    fz: PropTypes.number,
    newWidth: PropTypes.number,
    newHeight: PropTypes.number,
  }),
  src: PropTypes.string,
  root: PropTypes.string,
  showTooltip: PropTypes.bool,
  setHotspotData: PropTypes.func,
  placeLeftOrRight: PropTypes.bool,
};

export default HotspotWrapper;
