import useUserLocation from 'global-state/userLocation/useUserLocation';

// Note: this is the zip code from the user's location, which is derived in the
// userRefreshUserLocation hook. This is not always the zip code entered by the
// user, it could also be from the location from CloudFront or using the
// browser geolocation API. To access the the zip code entered by the user, use
// the useZipCodeFromUser hook instead.
const useZipCode = () => {
  const location = useUserLocation();

  return location?.zip ?? '';
};

export default useZipCode;
