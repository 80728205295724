import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import CtaButton from 'components/essentials/Cta/CtaButton';
import LabeledTextInput from 'components/consumer/LabeledTextInput';
import FormikFirstErrorFieldFocusOnSubmit from 'components/essentials/FormikFirstErrorFieldFocusOnSubmit';
import { logError } from 'commons/logger';
import ValidationError from 'components/consumer/ValidationError';
import useSetZipCodeFromUser from 'global-state/zipCodeFromUser/useSetZipCodeFromUser';
import { ZIPCODE_ERROR_MESSAGES } from 'commons/constants';
import useUserLocationFromZipcode from 'hooks/useUserLocationFromZipcode';
import useIsZipCodeServiceable from 'hooks/useIsZipCodeServiceable';
import PDPAlertBar from '../PDP/PDPAlertBar';

const INITIAL_VALUES = {
  zipcode: '',
};

const validateZipcodeInput = inputs => {
  const errors = {};

  if (!inputs.zipcode.toString().trim()) {
    errors.zipcode = ZIPCODE_ERROR_MESSAGES.ZIPCODE_IS_EMPTY;
  } else if (!/^\d{5}(-\d{4})?$/.test(inputs.zipcode)) {
    errors.zipcode = ZIPCODE_ERROR_MESSAGES.ZIPCODE_IS_INVALID_FORMAT;
  }

  return errors;
};

const UpdateZipcodeForm = ({ onUpdateSuccess }) => {
  const zipInputRef = useRef();
  const [isZipServiceable, setIsZipServiceable] = useState(true);

  const setZipCodeFromUser = useSetZipCodeFromUser();
  const { refetch: getIsZipCodeServiceable } = useIsZipCodeServiceable();

  const {
    refetch: getVisitorLocationFromZipcode,
  } = useUserLocationFromZipcode();

  const [nonServiceableZipError, setNonServiceableZipError] = useState('');

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={validateZipcodeInput}
      onSubmit={async ({ zipcode }, { setFieldError, setSubmitting }) => {
        setSubmitting(true);

        if (!zipcode) {
          setFieldError('zipcode', ZIPCODE_ERROR_MESSAGES.ZIPCODE_IS_EMPTY);
          return;
        }

        const trimmedZipCode = zipcode.trim();
        setZipCodeFromUser(trimmedZipCode);

        try {
          const {
            error: isZipCodeServiceableError,
            nonServiceableZipCodeError,
            isZipCodeServiceable: isServiceable,
          } = await getIsZipCodeServiceable(trimmedZipCode);

          if (!isServiceable) {
            setIsZipServiceable(isServiceable);

            if (isZipCodeServiceableError) {
              setFieldError('zipcode', isZipCodeServiceableError);
            }

            if (nonServiceableZipCodeError) {
              setNonServiceableZipError(nonServiceableZipCodeError);
            }

            return;
          }

          const {
            errorMessage: getLocationErrorMessage,
          } = await getVisitorLocationFromZipcode(trimmedZipCode);

          if (getLocationErrorMessage) {
            setFieldError('zipcode', getLocationErrorMessage);
            return;
          }

          onUpdateSuccess(isServiceable);
        } catch (error) {
          logError(error);
          setFieldError('zipcode', ZIPCODE_ERROR_MESSAGES.UNEXPECTED_ERROR);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <LabeledTextInput
            errorMessage={
              touched.zipcode && errors.zipcode ? errors.zipcode : null
            }
            id="zipcode"
            type="zipcode"
            name="zipcode"
            label="Zip code"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="zipcode"
            value={values.zipcode}
            data-xc="zipcode-field"
            ref={zipInputRef}
            autoFocus
          />

          <CtaButton
            hasArrow={false}
            color="black"
            type="submit"
            styling="solid-button"
            block
            isLoading={isSubmitting}
          >
            Update
          </CtaButton>

          {errors.formErrors && (
            <ValidationError block>{errors.formErrors}</ValidationError>
          )}

          <FormikFirstErrorFieldFocusOnSubmit
            formFieldRefs={{
              zipcode: zipInputRef,
            }}
          />

          {/* Showing zip code error message when zip is not serviceable */}
          {!isZipServiceable && (
            <PDPAlertBar> {nonServiceableZipError}</PDPAlertBar>
          )}
        </form>
      )}
    </Formik>
  );
};

UpdateZipcodeForm.propTypes = {
  onUpdateSuccess: PropTypes.func,
};

export default UpdateZipcodeForm;
