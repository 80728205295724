import { omit } from 'lodash';

import getIsProductShownForColorFilter from './getIsProductShownForColorFilter';
import getIsProductShownForListFilters from './getIsProductShownForListFilters';
import getIsProductShownForQuickshipFilter from './getIsProductShownForQuickshipFilter';
import getIsProductShownForSearchFilter from './getIsProductShownForSearchFilter';
import getIsProductShownForWidthRangeFilter from './getIsProductShownForWidthRangeFilter';

const getIsProductShown = ({
  activeFilterCount,
  activeFilters,
  attributes,
  productName,
  rangeAttributes,
  variants,
}) => {
  // If there are no active filters, all products are shown
  if (!activeFilterCount) {
    return true;
  }

  const isProductShownForColorFilter = getIsProductShownForColorFilter({
    activeFilters,
    variants,
  });

  const isProductShownForQuickshipFilter = getIsProductShownForQuickshipFilter({
    activeFilters,
    variants,
  });

  const isProductShownForSearchFilter = getIsProductShownForSearchFilter({
    activeFilters,
    productName,
  });

  const isProductShownForWidthRangeFilter = getIsProductShownForWidthRangeFilter(
    {
      activeFilters,
      rangeAttributes,
    }
  );

  const activeListFilters = omit(activeFilters, [
    'color',
    'quickship',
    'search',
    'widthRange',
  ]);

  const isProductShownForListFilters = getIsProductShownForListFilters({
    activeListFilters,
    attributes,
  });

  return (
    isProductShownForColorFilter &&
    isProductShownForListFilters &&
    isProductShownForQuickshipFilter &&
    isProductShownForSearchFilter &&
    isProductShownForWidthRangeFilter
  );
};

export default getIsProductShown;
