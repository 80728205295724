// Handle search filter separately because this involves a text filter instead
// of filtering by attribute values
const getIsProductShownForSearchFilter = ({ activeFilters, productName }) => {
  const searchFilterValue = activeFilters?.search?.[0];

  if (!searchFilterValue) {
    return true;
  }

  if (productName.toLowerCase().includes(searchFilterValue.toLowerCase())) {
    return true;
  }

  return false;
};

export default getIsProductShownForSearchFilter;
