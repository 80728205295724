import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import HotspotWrapper from './HotspotWrapper';

const HotspotWrapperRoot = ({
  children,
  className,
  dontFadeTagIcon,
  fromMobileGrid,
  isActive,
  isContentfulPromotionHero = false,
  showOnActive,
  style,
  ...props
}) => {
  const [rootRef, setRootRef] = useState(null);
  const createRootRef = useRef(null);
  useEffect(() => {
    if (createRootRef && createRootRef.current) {
      setRootRef(createRootRef.current);
    }
  }, [createRootRef]);
  return (
    <div
      ref={createRootRef}
      className={classNames({
        [className]: !!className && !fromMobileGrid,
        relative: !fromMobileGrid && !isContentfulPromotionHero,
        'static opacity-100 rounded-lg':
          !fromMobileGrid && isContentfulPromotionHero,
      })}
      style={style}
    >
      <HotspotWrapper
        rootRef={rootRef}
        {...props}
        isActive={isActive}
        dontFadeTagIcon={dontFadeTagIcon}
        fromMobileGrid={fromMobileGrid}
      />
      {children}
    </div>
  );
};

HotspotWrapperRoot.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dontFadeTagIcon: PropTypes.bool,
  fromMobileGrid: PropTypes.bool,
  isActive: PropTypes.bool,
  isContentfulPromotionHero: PropTypes.bool,
  root: PropTypes.string,
  setHotspotData: PropTypes.func,
  showOnActive: PropTypes.bool,
  showTooltip: PropTypes.bool,
  src: PropTypes.string,
  style: PropTypes.string,
};

export default HotspotWrapperRoot;
