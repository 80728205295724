import { atom } from 'jotai';

import getCookies from 'commons/getCookies';

const atomWithCookieStorage = (key, initialValue = '') => {
  const storedValue = getCookies()?.get(key);

  const baseAtom = atom(
    storedValue && typeof storedValue === 'string' ? storedValue : initialValue
  );

  return atom(
    get => get(baseAtom),
    (_get, set, nextValue) => {
      set(baseAtom, nextValue);
      getCookies()?.set(key, nextValue.toString(), {
        maxAge: 60 * 60 * 24 * 365, // 1 year
        path: '/',
      });
    }
  );
};

export default atomWithCookieStorage;
