import { useEffect } from 'react';
import { usePrevious } from 'react-use';

import useUserData from './index';

const useIsLoggedInCallback = callback => {
  const { isLoggedIn } = useUserData();
  const prevIsLoggedIn = usePrevious(isLoggedIn);

  useEffect(() => {
    if (isLoggedIn !== prevIsLoggedIn) {
      callback?.();
    }
  }, [isLoggedIn, prevIsLoggedIn, callback]);

  return isLoggedIn;
};

export default useIsLoggedInCallback;
